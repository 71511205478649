import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
import { ITransactionsCryptoSlice } from './index.D';
import { defaultPagination } from '../search/pagination.D';

const initialState = {
  transfer: [],
  customer: {
    items: [],
    info: defaultPagination,
  },
} as ITransactionsCryptoSlice;

const transactionsCryptoSlice = createSlice({
  name: 'transactionsCrypto',
  initialState,
  reducers: {
    clearTransfer(state: ITransactionsCryptoSlice) {
      state.transfer = [];
    },
    clearCustomer(state: ITransactionsCryptoSlice) {
      state.customer = {
        items: [],
        info: defaultPagination,
      };
    },
  },
  extraReducers: {
    [asyncActions.getTransactionsCryptoByTransfer.fulfilled.type]: (state, action) => {
      state.transfer = action.payload;
    },
    [asyncActions.getTransactionsCryptoByCustomer.fulfilled.type]: (state, action) => {
      state.customer = action.payload;
    },
  },
});

export default {
  ...transactionsCryptoSlice,
  asyncActions,
};
