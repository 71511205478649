import { createSlice } from '@reduxjs/toolkit';

import asyncActions from './asyncActions';
import { IRolesSlice } from './index.D';

const initialState: IRolesSlice = {
  roles: [],
  rolesOptions: [],
  permissions: '',
  role: null,
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: {
    [asyncActions.getRoles.fulfilled.type]: (state, action) => {
      state.roles = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getRolesByOrganization.fulfilled.type]: (state, action) => {
      state.roles = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getAvailableRoles.fulfilled.type]: (state, action) => {
      state.rolesOptions = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getPermissions.fulfilled.type]: (state, action) => {
      state.permissions = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getRole.fulfilled.type]: (state, action) => {
      state.role = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.updateRole.fulfilled.type]: (state, action) => {
      state.role = action.payload;
      state.isLoading = false;
      state.error = '';
    },
  },
});

export default {
  ...rolesSlice,
  asyncActions,
};
