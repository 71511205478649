import { createAsyncThunk } from '@reduxjs/toolkit';
import serviceAuth from '../../../services/auth';
import zendeskService from '../../../services/zendesk';
import { storeAddErrorNotification } from '../../../helpers/errors';
import { CheckCode, IChangePasswordPayload, ISetPassword } from './index.D';
import { WithMethod } from 'app/store/types';

export default {
  login: createAsyncThunk(
    'auth/login',
    async (params: Record<string, unknown>, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.login(params), thunkAPI, false)
  ),
  logout: createAsyncThunk(
    'auth/logout',
    async (params, thunkAPI) => await storeAddErrorNotification(async () => serviceAuth.logout(), thunkAPI, false)
  ),
  setPassword: createAsyncThunk(
    'admins/setPassword',
    async (params: ISetPassword, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.setPassword(params), thunkAPI, false)
  ),
  checkPasswordCode: createAsyncThunk(
    'admins/checkPasswordCode',
    async (code: string, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.checkPasswordCode(code), thunkAPI, false)
  ),

  login2fa: createAsyncThunk(
    'auth/login2fa',
    async (params: Record<string, unknown>, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.login2fa(params), thunkAPI, false)
  ),
  set2faMethod: createAsyncThunk(
    'auth/login-method',
    async (data: { method: number }, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.set2faMethod(data), thunkAPI, false)
  ),
  change2faMethod: createAsyncThunk(
    'auth/login-method-change',
    async (data: { method: number }, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.change2faMethod(data), thunkAPI, false)
  ),
  resendCode: createAsyncThunk(
    'auth/login-resend-code',
    async (data: undefined, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.resendCode(), thunkAPI, false)
  ),
  getAuthDetails: createAsyncThunk(
    'auth/details',
    async (data: undefined, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.getAuthDetails(), thunkAPI, false)
  ),
  confirmLogin: createAsyncThunk(
    'auth/login-verify',
    async (data: CheckCode, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.confirmLogin(data), thunkAPI, false)
  ),
  confirmLoginWithLoggedIn: createAsyncThunk(
    'auth/login-verify-with-logged-in',
    async (data: CheckCode, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.confirmLogin(data), thunkAPI, false)
  ),
  changePrimary2faMethod: createAsyncThunk(
    'auth/change-primary-2fa',
    async (data: WithMethod, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.changePrimary2faMethod(data), thunkAPI, false)
  ),

  checkCode: createAsyncThunk(
    'auth/check-code-2fa',
    async (data: CheckCode, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.checkCode(data), thunkAPI, false)
  ),
  updateSession: createAsyncThunk(
    'auth/update',
    async (data: undefined, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.updateSession(), thunkAPI, false)
  ),
  updateSessionActivity: createAsyncThunk(
    'auth/activity',
    async (data: number, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.updateSessionActivity(data), thunkAPI, false)
  ),
  setPhone: createAsyncThunk(
    'auth/set-phone',
    async (data: { phone: string }, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.setPhone(data), thunkAPI, false)
  ),

  resetPassword: createAsyncThunk(
    'auth/reset-password',
    async (data: { email: string }, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.resetPassword(data), thunkAPI, false)
  ),
  getPasswordVerify: createAsyncThunk(
    'auth/password-verify',
    async (params: { code: string }, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.getPasswordVerify(params), thunkAPI, false)
  ),
  confirmPasswordReset: createAsyncThunk(
    'auth/password-confirm',
    async (params: IChangePasswordPayload, thunkAPI) =>
      await storeAddErrorNotification(() => serviceAuth.confirmPasswordReset(params), thunkAPI, false)
  ),
  zendeskSSOLogin: createAsyncThunk(
    'zendesk/login',
    async (data: { params: Record<string, unknown> }, thunkAPI) =>
      await storeAddErrorNotification(() => zendeskService.zendeskSSOLogin(data.params), thunkAPI, false)
  ),
  zendeskSSOLoginVerify: createAsyncThunk(
    'zendesk/login/verify',
    async (data: { code: string; query: Record<string, string> }, thunkAPI) =>
      await storeAddErrorNotification(
        () => zendeskService.zendeskSSOConfirmLogin(data.code, data.query),
        thunkAPI,
        false
      )
  ),
};
