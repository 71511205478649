import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/supported-documents',
    title: 'Supported Documents',
    component: lazy(() => import('../../../components/views/ManageSupportedDocuments')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['documents_read'],
    routes: [],
  },
  {
    path: '/supported-documents/:id',
    title: 'Proof Details',
    component: lazy(() => import('../../../components/views/ManageSupDocumentDetail')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['documents_read'],
    routes: [
      {
        path: '/supported-documents',
        title: 'Supported Documents',
        params: [],
      },
    ],
  },
  {
    path: '/supported-documents/proofs/create',
    title: 'Proof Create',
    component: lazy(() => import('../../../components/views/ManageSupDocumentCreate')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['documents_create'],
    routes: [
      {
        path: '/supported-documents',
        title: 'Supported Documents',
        params: [],
      },
    ],
  },
] as IRoutes[];
