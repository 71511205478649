// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
import objectToURL from '../../helpers/objectToURL';
// TYPES
import { IParams } from '../../store/reducers/regularFee/index.D';

const { get, post, patch, delete: del, put } = apiClient;
const URL = () => `organizations/${getBrand()}/customers`;

export default {
  getCustomerFees: (customerId: string, params: IParams) =>
    get(`${URL()}/${customerId}/regular-fees?${objectToURL(params)}`),
};
