import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import announcementService from '../../../services/announcement';

export default {
  sendGlobalAnnouncement: createAsyncThunk(
    'announcements/send-global',
    async (message: string, thunkAPI) =>
      await storeAddErrorNotification(() => announcementService.sendGlobalAnnouncement(message), thunkAPI, false)
  ),
};
