import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';

const { get } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default {
  getSpVerification: (verificationId: string) => get(`${URL()}/customers/sp-verification/${verificationId}`),
  getSpVerificationBusiness: (verificationId: string) => get(`${URL()}/business/sp-verification/${verificationId}`),
};
