// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import clientsService from '../../../services/clients';

export default {
  search: createAsyncThunk(
    'clients/search',
    async (params: Record<string, string | number>, thunkAPI) =>
      await storeAddErrorNotification(() => clientsService.search(params), thunkAPI)
  ),
  getFirstMatchUid: createAsyncThunk(
    'clients/first-uid',
    async (params: Record<string, string | number>, thunkAPI) =>
      await storeAddErrorNotification(() => clientsService.getFirstMatchUid(params), thunkAPI)
  ),
};
