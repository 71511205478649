import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import feeService from '../../../services/fees';
// TYPES
import {
  IUpdateFee,
  IQueriesFees,
  ICreateFeeBase,
  IUpdateFeeBase,
  IFeeOpeningCreate,
  IFeeOpening,
  TCreateFeeRefund,
} from './index.D';
import { IFeeValue } from '../../../../components/modules/form/FeesForm/index.D';

export default {
  // PRODUCT FEES
  getFees: createAsyncThunk(
    'fees/get',
    async (params: IQueriesFees, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.getFees(params), thunkAPI, false)
  ),
  deleteFee: createAsyncThunk(
    'fees/del',
    async (id: string, thunkAPI) => await storeAddErrorNotification(() => feeService.deleteFee(id), thunkAPI, false)
  ),
  createFee: createAsyncThunk(
    'fees/create',
    async (data: IFeeValue, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.createFee(data), thunkAPI, false)
  ),
  updateFee: createAsyncThunk(
    'fees/update',
    async (fee: IUpdateFee, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.updateFee(fee.id, fee.data), thunkAPI, false)
  ),
  // BASE FEES
  getBaseFee: createAsyncThunk(
    'feeBase/get',
    async (params, thunkAPI) => await storeAddErrorNotification(() => feeService.getBaseFee(), thunkAPI, false)
  ),
  createBaseFee: createAsyncThunk(
    'feeBase/create',
    async (data: ICreateFeeBase, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.createBaseFee(data), thunkAPI, false)
  ),
  updateBaseFee: createAsyncThunk(
    'feeBase/update',
    async (fee: IUpdateFeeBase, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.updateBaseFee(fee.id, fee.data), thunkAPI, false)
  ),
  // ACCOUNT OPENING FEES
  getOpeningFees: createAsyncThunk(
    'feesOpening/get',
    async (params: IQueriesFees, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.getOpeningFees(params), thunkAPI, false)
  ),
  createOpeningFee: createAsyncThunk(
    'feesOpening/create',
    async (params: IFeeOpeningCreate, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.createOpeningFee(params), thunkAPI, false)
  ),
  deleteOpeningFee: createAsyncThunk(
    'feesOpening/delete',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.deleteOpeningFee(id), thunkAPI, false)
  ),
  updateOpeningFee: createAsyncThunk(
    'feesOpening/update',
    async (params: { id: string; data: Partial<IFeeOpening> }, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.updateOpeningFee(params.id, params.data), thunkAPI, false)
  ),
  // REFUND FEES
  getRefundFees: createAsyncThunk(
    'feeRefund/ger',
    async (params: IQueriesFees, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.getRefundFees(params), thunkAPI, false)
  ),
  createRefundFees: createAsyncThunk(
    'feeRefund/create',
    async (data: TCreateFeeRefund, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.createRefundFee(data), thunkAPI, false)
  ),
  updateRefundFee: createAsyncThunk(
    'feeRefund/update',
    async (params: { id: string; data: Partial<TCreateFeeRefund> }, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.updateFeeRefund(params.id, params.data), thunkAPI, false)
  ),
  deleteRefundFee: createAsyncThunk(
    'feeRefund/delete',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => feeService.deleteFeeRefund(id), thunkAPI, false)
  ),
};
