import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import proofService from '../../../services/proofs';
// TYPES
import {
  IQueriesRules,
  IProofData,
  IUpdateProof,
  IUpdateAuthProof,
  ICreateVerificationProof,
  IParams,
} from './index.D';

export default {
  getProofs: createAsyncThunk(
    'proofs/get',
    async (params: IQueriesRules, thunkAPI) =>
      await storeAddErrorNotification(() => proofService.getProofs(params), thunkAPI, false)
  ),
  getProofById: createAsyncThunk(
    'proof/getById',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => proofService.getProofById(id), thunkAPI, false)
  ),
  createProof: createAsyncThunk(
    'proofs/create',
    async (data: IProofData, thunkAPI) =>
      await storeAddErrorNotification(() => proofService.createProof(data), thunkAPI, false)
  ),
  updateProof: createAsyncThunk(
    'proofs/update',
    async (data: IUpdateProof, thunkAPI) =>
      await storeAddErrorNotification(() => proofService.updateProof(data), thunkAPI, false)
  ),
  deleteProof: createAsyncThunk(
    'proofs/delete',
    async (id: string, thunkAPI) => await storeAddErrorNotification(() => proofService.deleteProof(id), thunkAPI, false)
  ),
  getAuthProofByType: createAsyncThunk(
    'proofs/getAuthBusiness',
    async (params: IQueriesRules, thunkAPI) =>
      await storeAddErrorNotification(() => proofService.getAuthProofByType(params), thunkAPI, false)
  ),
  updateAuthProof: createAsyncThunk(
    'proofs/updateAuthProof',
    async (data: IUpdateAuthProof, thunkAPI) =>
      await storeAddErrorNotification(() => proofService.updateAuthProof(data), thunkAPI, false)
  ),
  createVerificationProof: createAsyncThunk(
    'proofs/createVerificationProof',
    async (data: ICreateVerificationProof, thunkAPI) =>
      await storeAddErrorNotification(() => proofService.createVerificationProof(data), thunkAPI, false)
  ),
  getUnloadedProof: createAsyncThunk(
    'proofs/getUnloadedProof',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => proofService.getUnloadedProof(id), thunkAPI, false)
  ),
  getVerificationProofs: createAsyncThunk(
    'proofs/getVerificationProofs',
    async (params: IParams, thunkAPI) =>
      await storeAddErrorNotification(() => proofService.getVerificationProofs(params), thunkAPI, false)
  ),
  deleteProofRequest: createAsyncThunk(
    'proof/deleteProofRequest',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => proofService.deleteProofRequest(id), thunkAPI, false)
  ),
};
