import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultPagination } from '../search/pagination.D';
import asyncActions from './asyncActions';
import { IAdmin, IAdminsSlice } from './index.D';

const initialState = {
  admins: {
    items: [],
    info: defaultPagination,
  },
  admin: {
    auth: {},
  } as IAdmin,
  isLoading: false,
  isLoadingAdmin: false,
  error: '',
} as IAdminsSlice;

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {},
  extraReducers: {
    [asyncActions.getAdmins.fulfilled.type]: (state: IAdminsSlice, action) => {
      state.admins = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getAdmins.pending.type]: (state: IAdminsSlice) => {
      state.isLoading = true;
    },
    [asyncActions.getAdmin.fulfilled.type]: (state: IAdminsSlice, action) => {
      state.admin = action.payload;
      state.isLoadingAdmin = false;
      state.error = '';
    },
    [asyncActions.getAdmin.pending.type]: (state: IAdminsSlice) => {
      state.isLoadingAdmin = true;
    },
    [asyncActions.deleteAdmin.fulfilled.type]: (state: IAdminsSlice, { payload }) => {
      state.admin = payload;
      state.isLoading = false;
    },
    [asyncActions.updateAdmin.fulfilled.type]: (state: IAdminsSlice, { payload }: PayloadAction<IAdmin>) => {
      const index = state.admins.items.findIndex(({ _id }) => _id === payload._id);
      const admin = state.admins.items[index];
      const newAdmins = [...state.admins.items];
      newAdmins[index] = { ...admin, ...payload };
      state.admins.items = newAdmins;
    },
  },
});

export default {
  ...adminsSlice,
  asyncActions,
};
