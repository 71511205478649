import { createSlice } from '@reduxjs/toolkit';
import { defaultPagination } from '../search/pagination.D';
// TYPES
import { IBalanceFeeSlice, IFeeBalance } from './index.D';
// ASYNC ACTIONS
import asyncActions from './asyncActions';

const initialState = {
  balanceFees: {
    items: [] as IFeeBalance[],
    info: defaultPagination,
  },
  isLoading: false,
} as IBalanceFeeSlice;

const balanceFeeSlice = createSlice({
  name: 'balanceFees',
  initialState,
  reducers: {},
  extraReducers: {
    [asyncActions.getBalanceFees.fulfilled.type]: (state, action) => {
      state.balanceFees = action.payload;
      state.isLoading = false;
    },
  },
});

export default {
  ...balanceFeeSlice,
  asyncActions,
};
