import { createNotification } from '../notification';
import handlerFetchError from '../errors/handlerFetchError';
import { Dispatch } from '@reduxjs/toolkit';

const storeAddErrorNotification = async (
  action = () => ({}),
  { dispatch, rejectWithValue }: { dispatch: Dispatch; rejectWithValue: any },
  auth = true
) => {
  try {
    return await action?.();
  } catch (error: any) {
    const { notificationMessage, status } = handlerFetchError(error);
    if (notificationMessage) {
      createNotification(dispatch, notificationMessage, {
        variant: 'error',
      });
    }

    return rejectWithValue(error);
  }
};

export default storeAddErrorNotification;
