import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
import { IOrganization, IOrganizationSlice } from './index.D';

const initialState: IOrganizationSlice = {
  organizations: {
    items: [],
    info: {
      current_page: 1,
      next_page: null,
      order: null,
      order_asc_desc: 'asc',
      per_page: 10,
      previous_page: null,
      total_entries: 0,
      total_pages: 1,
    },
  },
  organizationList: [],
  allOrganizationsList: [],
  organization: null,
  isLoading: false,
  isHostLoading: false,
  subOrgChanged: false,
};

const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setSubOrgChanged: (state, action) => {
      state.subOrgChanged = action.payload;
    },
    setCurrentSubOrg: (state, action) => {
      state.currentSubOrg = action.payload;
    },
  },
  extraReducers: {
    [asyncActions.getOrganization.fulfilled.type]: (state: IOrganizationSlice, action) => {
      state.organization = action.payload;
      state.isHostLoading = false;
    },
    [asyncActions.getOrganization.pending.type]: (state: IOrganizationSlice) => {
      state.isHostLoading = true;
    },
    [asyncActions.getOrganization.rejected.type]: (state: IOrganizationSlice) => {
      state.isHostLoading = false;
    },
    [asyncActions.getOrganizationsByAdmin.fulfilled.type]: (state: IOrganizationSlice, action) => {
      state.organizations = action.payload;
    },
    [asyncActions.getOrganizations.fulfilled.type]: (state: IOrganizationSlice, action) => {
      state.organizationList = action.payload;
    },
    [asyncActions.getOrganizationsByRole.fulfilled.type]: (state: IOrganizationSlice, action) => {
      state.organizationList = action.payload;
    },
    [asyncActions.getAllOrganizations.fulfilled.type]: (state: IOrganizationSlice, action) => {
      state.allOrganizationsList = action.payload;
      state.isLoading = false;
    },
    [asyncActions.getAllOrganizations.pending.type]: (state: IOrganizationSlice, action) => {
      state.isLoading = true;
    },
    [asyncActions.getAllOrganizations.rejected.type]: (state: IOrganizationSlice, action) => {
      state.isLoading = false;
    },
    [asyncActions.createOrganization.fulfilled.type]: (state, action: { payload: IOrganization }) => {
      state.isLoading = false;
      state.allOrganizationsList.push(action.payload);
    },
    [asyncActions.createOrganization.pending.type]: state => {
      state.isLoading = true;
    },
    [asyncActions.createOrganization.rejected.type]: state => {
      state.isLoading = false;
    },
    [asyncActions.updateOrganization.fulfilled.type]: (state, action: { payload: IOrganization }) => {
      state.isLoading = false;
      const index = state.allOrganizationsList.findIndex(org => org._id === action.payload._id);
      state.allOrganizationsList[index] = action.payload;
    },
    [asyncActions.updateOrganization.pending.type]: state => {
      state.isLoading = true;
    },
    [asyncActions.updateOrganization.rejected.type]: state => {
      state.isLoading = false;
    },
    [asyncActions.deleteOrganization.fulfilled.type]: (state, action: { payload: IOrganization }) => {
      state.isLoading = false;
      state.allOrganizationsList = state.allOrganizationsList.filter(org => org._id !== action.payload._id);
    },
    [asyncActions.deleteOrganization.pending.type]: state => {
      state.isLoading = true;
    },
    [asyncActions.deleteOrganization.rejected.type]: state => {
      state.isLoading = false;
    },
    [asyncActions.deployOrganization.fulfilled.type]: (state, action: { payload: IOrganization }) => {
      state.isLoading = false;
      const index = state.allOrganizationsList.findIndex(org => org._id === action.payload._id);
      state.allOrganizationsList[index] = action.payload;
    },
    [asyncActions.deployOrganization.pending.type]: state => {
      state.isLoading = true;
    },
    [asyncActions.deployOrganization.rejected.type]: state => {
      state.isLoading = false;
    },
  },
});

export default {
  ...organizationSlice,
  asyncActions,
};
