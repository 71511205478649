// AXIOS
import { apiClient } from '../../clients/api.client';
// HELPERS
import objectToURL from '../../helpers/objectToURL';
import getBrand from '../../helpers/getBrand';

const { get, delete: del } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default {
  getLogs: (params: { [key: string]: string }) => get(`${URL()}/logs?${objectToURL(params)}`),
  getLogById: (id: string) => get(`${URL()}/logs/${id}`),
  deleteLog: (id: string) => del(`${URL()}/logs/${id}`),
};
