// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import transactionsCostsService from '../../../../app/services/transactionsCosts';
import { ICreateTransactionCostPayload } from './index.D';

export default {
  create: createAsyncThunk('transactionCost/create', async (data: ICreateTransactionCostPayload, thunkAPI) =>
    storeAddErrorNotification(() => transactionsCostsService.create(data), thunkAPI)
  ),
  update: createAsyncThunk(
    'transactionCost/update',
    async ({ id, data }: { id: string; data: Partial<ICreateTransactionCostPayload> }, thunkAPI) =>
      storeAddErrorNotification(() => transactionsCostsService.update(id, data), thunkAPI)
  ),
  delete: createAsyncThunk('transactionCost/delete', async (id: string, thunkAPI) =>
    storeAddErrorNotification(() => transactionsCostsService.delete(id), thunkAPI)
  ),

  readFreeMarketCosts: createAsyncThunk('transactionFMCosts/read', async (params: Record<string, string>, thunkAPI) =>
    storeAddErrorNotification(() => transactionsCostsService.readFreeMarketCosts(params), thunkAPI)
  ),

  readKrakenFiat: createAsyncThunk(
    'transactionKrakenFiatCosts/read',
    async (params: Record<string, string>, thunkAPI) =>
      storeAddErrorNotification(() => transactionsCostsService.readKrakenFiat(params), thunkAPI)
  ),

  readKrakenCrypto: createAsyncThunk(
    'transactionKrakenCryptoCosts/read',
    async (params: Record<string, string>, thunkAPI) =>
      storeAddErrorNotification(() => transactionsCostsService.readKrakenCrypto(params), thunkAPI)
  ),

  readKrakenOrders: createAsyncThunk(
    'transactionKrakenOrdersCosts/read',
    async (params: Record<string, string>, thunkAPI) =>
      storeAddErrorNotification(() => transactionsCostsService.readKrakenOrders(params), thunkAPI)
  ),
};
