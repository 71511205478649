import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/onboarding-requests',
    title: 'Onboarding requests',
    component: lazy(() => import('../../../components/views/RegistrationRequests')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['registration_requests_read'],
    routes: [],
  },
  {
    path: '/onboarding-requests/:requestId',
    title: 'Onboarding request details',
    component: lazy(() => import('../../../components/views/RegistrationRequestDetails')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['registration_requests_read'],
    routes: [
      {
        title: 'Onboarding requests',
        path: '/onboarding-requests',
        params: [],
      },
    ],
  },
] as IRoutes[];
