// AXIOS
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { IAssignAccountPayload } from '../../store/reducers/freeMarket/index.D';

const { get, post } = apiClient;

const URL = () => `organizations/${getBrand()}/free-market`;

export default {
  getAccountReference: (customerId: string) => get(`${URL()}/reference/${customerId}`),
  assignFreeMarketAccount: (data: IAssignAccountPayload) => post(`${URL()}/assign`, data),
};
