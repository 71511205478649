// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import waitListTransfers from '../../../services/waitList';

export default {
  getWaitList: createAsyncThunk(
    'waitList/get',
    async (params: Record<string, unknown>, thunkAPI) =>
      await storeAddErrorNotification(() => waitListTransfers.getWaitList(params), thunkAPI, false)
  ),
};
