import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { IPayTierSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState = {
  items: [],
  isLoading: false,
} as IPayTierSlice;

const payTierSlice = createSlice({
  name: 'payTiers',
  initialState,
  reducers: {
    clearPayTiers(state: IPayTierSlice) {
      state.items = [];

      return state;
    },
  },
  extraReducers: {
    [asyncActions.getPayTiers.fulfilled.type]: (state: IPayTierSlice, action) => {
      state.items = action.payload;
      state.isLoading = false;
    },
  },
});

export default {
  ...payTierSlice,
  asyncActions,
};
