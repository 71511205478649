import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { ICurrenciesSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState = {
  currencies: {
    items: [],
    info: {
      current_page: 1,
      next_page: null,
      order: null,
      order_asc_desc: 'asc',
      per_page: 10,
      previous_page: null,
      total_entries: 0,
      total_pages: 1,
    },
  },
  fiat: [],
  isLoading: false,
  list: [],
} as ICurrenciesSlice;

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    clearCurrencies(state: ICurrenciesSlice) {
      state.currencies = {
        items: [],
        info: {
          current_page: 1,
          next_page: null,
          order: null,
          order_asc_desc: 'asc',
          per_page: 10,
          previous_page: null,
          total_entries: 0,
          total_pages: 1,
        },
      };

      return state;
    },
  },
  extraReducers: {
    [asyncActions.getCurrencies.fulfilled.type]: (state: ICurrenciesSlice, action) => {
      state.currencies.items = [
        ...new Map([...state.currencies.items, ...action.payload.items].map(item => [item._id, item])).values(),
      ];
      state.currencies.info = action.payload.info;
      state.isLoading = false;
    },
    [asyncActions.getCurrencies.pending.type]: (state: ICurrenciesSlice) => {
      state.isLoading = true;
    },
    [asyncActions.getCurrencies.rejected.type]: (state: ICurrenciesSlice) => {
      state.isLoading = false;
    },
    [asyncActions.getCryptoConvertable.pending.type]: (state: ICurrenciesSlice) => {
      state.isLoading = true;
    },
    [asyncActions.getCryptoConvertable.rejected.type]: (state: ICurrenciesSlice) => {
      state.isLoading = false;
    },
    [asyncActions.getCryptoConvertable.fulfilled.type]: (state: ICurrenciesSlice, action) => {
      state.fiat = action.payload;
    },
    [asyncActions.getAll.fulfilled.type]: (state: ICurrenciesSlice, action) => {
      state.list = action.payload;
    },
  },
});

export default {
  ...currenciesSlice,
  asyncActions,
};
