import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import currenciesService from '../../../services/currencies';
// TYPES
import { IQueriesCurrency } from './index.D';

export default {
  getCurrencies: createAsyncThunk(
    'currencies/get',
    async (params: IQueriesCurrency, thunkAPI) =>
      await storeAddErrorNotification(() => currenciesService.get(params), thunkAPI, false)
  ),
  getCryptoConvertable: createAsyncThunk(
    'currencies/getCryptoConvertable',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(() => currenciesService.getCryptoConvertable(), thunkAPI, false)
  ),
  getAll: createAsyncThunk(
    'currencies/getAll',
    async (params, thunkAPI) => await storeAddErrorNotification(() => currenciesService.getAll(), thunkAPI, false)
  ),
};
