import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/settings',
    title: 'Settings',
    component: lazy(() => import('../../../components/views/Settings')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: [],
    routes: [],
  },
] as IRoutes[];
