import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
import { IFreeMarketSlice } from './index.D';

const initialState = {
  loading: false,
  currentAccountReference: null,
} as IFreeMarketSlice;

const freeMarketSlice = createSlice({
  name: 'freeMarket',
  initialState,
  reducers: {
    clearCurrentAccountReference: state => {
      state.currentAccountReference = null;
    },
  },
  extraReducers: {
    // GET ACCOUNT REFERENCE
    [asyncActions.getAccountReference.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.getAccountReference.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.getAccountReference.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.currentAccountReference = action.payload;
    },
    // ASSIGN ACCOUNT
    [asyncActions.assignFreeMarketAccount.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.assignFreeMarketAccount.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.assignFreeMarketAccount.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.currentAccountReference = action.payload;
    },
  },
});

export default {
  ...freeMarketSlice,
  asyncActions,
};
