// core
import { createAsyncThunk } from '@reduxjs/toolkit';

// services
import batchedTransfersService from '../../../services/batchedTransfers';

export default {
  getBatchesTransfer: createAsyncThunk(
    '/batched-transfer/get',
    async (params: { transferId: string }) => await batchedTransfersService.getBatchedTransfer(params)
  ),
  executeBulkTransfer: createAsyncThunk(
    '/batched-transfer/execute',
    async (params: { transferId: string; refid: string }) => await batchedTransfersService.executeBulkTransfer(params)
  ),
  getBatchedTransferTransactions: createAsyncThunk(
    '/batched-transfer-transactions',
    async (params: Record<string, any>) => await batchedTransfersService.getBatchedTransfersTransactions(params)
  ),
  loadMoreTransferTransactions: createAsyncThunk(
    '/batched-transfer-transactions/load-more',
    async (params: Record<string, any>) => await batchedTransfersService.getBatchedTransfersTransactions(params)
  ),
};
