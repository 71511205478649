import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultPagination } from '../search/pagination.D';
import asyncActions from './asyncActions';
import { ITransaction, ITransactionsSlice } from './index.D';

const initialState = {
  transfer: [],
  transactions: {
    transactions: [],
    pagination: defaultPagination,
  },
  isLoading: false,
  error: '',
  selected: [],
} as ITransactionsSlice;

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setSelected: (state: ITransactionsSlice, payload: PayloadAction<Array<ITransaction['id']> | []>) => {
      state.selected = payload.payload;
    },
    clearTransactions(state: ITransactionsSlice) {
      state.transactions = {
        transactions: [],
        pagination: {
          current_page: 1,
          next_page: null,
          order: null,
          order_asc_desc: 'asc',
          per_page: 10,
          previous_page: null,
          total_entries: 0,
          total_pages: 1,
        },
      };

      return state;
    },
  },
  extraReducers: {
    [asyncActions.getTransfer.fulfilled.type]: (state, action) => {
      state.transfer = action.payload;
    },
    [asyncActions.getTransactionsByTransfer.fulfilled.type]: (state, action) => {
      state.transactions.transactions = action.payload;
    },
  },
});

export default {
  ...transactionsSlice,
  asyncActions,
};
