// CORE
import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { IMarginsFeesSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';
import { defaultPagination } from '../search/pagination.D';

const getPlanTierKey = (arg: Record<string, any>) =>
  arg?.pricePlan ? arg.pricePlan.toLowerCase() : arg?.basePriceTier ? arg.basePriceTier.toLowerCase() : 'master';

const initialState: IMarginsFeesSlice = {
  account: {
    master: {
      isLoading: false,
      fees: {
        items: [],
        info: defaultPagination,
      },
    },
  },
  asset: {
    master: {
      isLoading: false,
      fees: {
        items: [],
        info: defaultPagination,
      },
    },
  },
  conversionPair: {
    master: {
      isLoading: false,
      fees: {
        items: [],
        info: defaultPagination,
      },
    },
  },
  conversionGlobal: {
    master: {
      isLoading: false,
      fees: {
        items: [],
        info: defaultPagination,
      },
    },
  },
  traidingPair: {
    master: {
      isLoading: false,
      fees: {
        items: [],
        info: defaultPagination,
      },
    },
  },
  traidingGlobal: {
    master: {
      isLoading: false,
      fees: {
        items: [],
        info: defaultPagination,
      },
    },
  },
  conversionCryptoPair: {
    master: {
      isLoading: false,
      fees: {
        items: [],
        info: defaultPagination,
      },
    },
  },
  conversionCryptoGlobal: {
    master: {
      isLoading: false,
      fees: {
        items: [],
        info: defaultPagination,
      },
    },
  },
  transfer: {
    master: {
      isLoading: false,
      fees: {
        items: [],
        info: defaultPagination,
      },
    },
  },
};

const marginsFeesSlice = createSlice({
  name: 'marginsFees',
  initialState,
  reducers: {},
  extraReducers: {
    // SEND/RECEIVE MONEY
    [asyncActions.readAccountPlatformFees.fulfilled.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.account[key].isLoading = false;
      state.account[key].fees = action.payload;
    },
    [asyncActions.readAccountPlatformFees.pending.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.account[key] = {
        isLoading: false,
        fees: {
          items: [],
          info: defaultPagination,
        },
      };
      state.account[key].isLoading = true;
    },
    [asyncActions.readAccountPlatformFees.rejected.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.account[key].isLoading = false;
    },

    // ACCOUNT CONVERSION PAIR
    [asyncActions.readAccountConverionPair.fulfilled.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionPair[key].isLoading = false;
      state.conversionPair[key].fees = action.payload;
    },
    [asyncActions.readAccountConverionPair.pending.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionPair[key] = {
        isLoading: false,
        fees: {
          items: [],
          info: defaultPagination,
        },
      };
      state.conversionPair[key].isLoading = true;
    },
    [asyncActions.readAccountConverionPair.rejected.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionPair[key].isLoading = false;
    },

    // CONVERSION GLOBAL
    [asyncActions.readFiatConversionGlobal.fulfilled.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionGlobal[key].isLoading = false;
      state.conversionGlobal[key].fees = action.payload;
    },
    [asyncActions.readFiatConversionGlobal.pending.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionGlobal[key] = {
        isLoading: false,
        fees: {
          items: [],
          info: defaultPagination,
        },
      };
      state.conversionGlobal[key].isLoading = true;
    },
    [asyncActions.readFiatConversionGlobal.rejected.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionGlobal[key].isLoading = false;
    },

    // ASSETS - CRYPTO DEPOSIT / WITHDRAWAL
    [asyncActions.readAssetFees.fulfilled.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.asset[key].isLoading = false;
      state.asset[key].fees = action.payload;
    },
    [asyncActions.readAssetFees.pending.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.asset[key] = {
        isLoading: false,
        fees: {
          items: [],
          info: defaultPagination,
        },
      };
      state.asset[key].isLoading = true;
    },
    [asyncActions.readAssetFees.rejected.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.asset[key].isLoading = false;
    },

    // SELL/BUY CRYPTO PAIR
    [asyncActions.readAssetTraidingPairFees.fulfilled.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.traidingPair[key].isLoading = false;
      state.traidingPair[key].fees = action.payload;
    },
    [asyncActions.readAssetTraidingPairFees.pending.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.traidingPair[key] = {
        isLoading: false,
        fees: {
          items: [],
          info: defaultPagination,
        },
      };
      state.traidingPair[key].isLoading = true;
    },
    [asyncActions.readAssetTraidingPairFees.rejected.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.traidingPair[key].isLoading = false;
    },

    // SELL/BUY CRYPTO GLOBAL
    [asyncActions.readAssetTraidingGlobalFees.fulfilled.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.traidingGlobal[key].isLoading = false;
      state.traidingGlobal[key].fees = action.payload;
    },
    [asyncActions.readAssetTraidingGlobalFees.pending.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.traidingGlobal[key] = {
        isLoading: false,
        fees: {
          items: [],
          info: defaultPagination,
        },
      };
      state.traidingGlobal[key].isLoading = true;
    },
    [asyncActions.readAssetTraidingGlobalFees.rejected.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.traidingGlobal[key].isLoading = false;
    },

    // CONVERSION CRYPTO PAIR
    [asyncActions.readConversionCryptoPair.fulfilled.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionCryptoPair[key].isLoading = false;
      state.conversionCryptoPair[key].fees = action.payload;
    },
    [asyncActions.readConversionCryptoPair.pending.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionCryptoPair[key] = {
        isLoading: false,
        fees: {
          items: [],
          info: defaultPagination,
        },
      };
      state.conversionCryptoPair[key].isLoading = true;
    },
    [asyncActions.readConversionCryptoPair.rejected.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionCryptoPair[key].isLoading = false;
    },

    // CONVERSION CRYPTO GLOBAL
    [asyncActions.readConversionCryptoGlobal.fulfilled.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionCryptoGlobal[key].isLoading = false;
      state.conversionCryptoGlobal[key].fees = action.payload;
    },
    [asyncActions.readConversionCryptoGlobal.pending.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionCryptoGlobal[key] = {
        isLoading: false,
        fees: {
          items: [],
          info: defaultPagination,
        },
      };
      state.conversionCryptoGlobal[key].isLoading = true;
    },
    [asyncActions.readConversionCryptoGlobal.rejected.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.conversionCryptoGlobal[key].isLoading = false;
    },

    // TRANSFER FEES
    [asyncActions.readTransferFees.fulfilled.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.transfer[key].isLoading = false;
      state.transfer[key].fees = action.payload;
    },
    [asyncActions.readTransferFees.pending.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.transfer[key] = {
        isLoading: false,
        fees: {
          items: [],
          info: defaultPagination,
        },
      };
      state.transfer[key].isLoading = true;
    },
    [asyncActions.readTransferFees.rejected.type]: (state: IMarginsFeesSlice, action) => {
      const {
        meta: { arg },
      } = action;
      const key = getPlanTierKey(arg);

      state.transfer[key].isLoading = false;
    },
  },
});

export default {
  ...marginsFeesSlice,
  asyncActions,
};
