import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/customers/:customerId/beneficiaries/:id',
    title: 'Beneficiary Details',
    component: lazy(() => import('../../../components/views/BeneficiarDetail')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['accounts_read', 'accounts_report'],
    routes: [
      {
        title: 'Customers',
        path: '/customers',
        params: [],
      },
      {
        title: 'Customers Details',
        path: '/customers/:customerId',
        params: ['customerId'],
      },
      {
        title: 'Beneficiaries',
        path: '/customers/:customerId#beneficiaries',
        params: ['customerId'],
      },
    ],
  },
  {
    path: '/businesses/:businessId/beneficiaries/:id',
    title: 'Beneficiary Details',
    component: lazy(() => import('../../../components/views/BeneficiarDetail')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['accounts_read', 'accounts_report'],
    routes: [
      {
        title: 'Businesses',
        path: '/businesses',
        params: [],
      },
      {
        title: 'Business Details',
        path: '/businesses/:businessId',
        params: ['businessId'],
      },
      {
        title: 'Beneficiaries',
        path: '/businesses/:businessId#beneficiaries',
        params: ['businessId'],
      },
    ],
  },
] as IRoutes[];
