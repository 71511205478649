import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/alerts',
    title: 'Alerts',
    component: lazy(() => import('../../../components/views/RiskAlerts')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['risk_alerts_read'],
    routes: [],
  },
  {
    path: '/alerts/:id',
    title: 'Alert Details',
    component: lazy(() => import('../../../components/views/RiskAlertDetail')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['risk_alerts_read'],
    routes: [
      {
        path: '/alerts',
        title: 'Alerts',
        params: [],
      },
    ],
  },
] as IRoutes[];
