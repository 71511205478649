// AXIOS
import { apiClient } from '../../clients/api.client';
// TYPES
import { IRoleCredentials } from 'components/modules/UserPermissionsSelect/index.D';
// HELPERS
import getBrand from '../../helpers/getBrand';

const { get, patch } = apiClient;
const URL = () => `/organizations/${getBrand()}/roles`;

export default {
  getRoles: () => get(`${URL()}`),
  getRolesByOrganization: (id: string) => get(`organizations/${id}/roles`),
  getAvailableRoles: (id: string) => get(`organizations/${id}/roles/list`),
  getPermissions: () => get(`${URL()}/permissions`),
  getRole: (roleId: string) => get(`${URL()}/${roleId}`),
  updateRole: ({ _id, ...params }: Partial<IRoleCredentials>) => patch(`${URL()}/${_id}`, params),
};
