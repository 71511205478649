// core
import { createAsyncThunk } from '@reduxjs/toolkit';

// services
import batchedTransfersLogsService from '../../../services/batchedTransfersLogs';

export default {
  getTransfersLogs: createAsyncThunk(
    '/kraken-logs/get',
    async (params: Record<string, any>) => await batchedTransfersLogsService.getTransfersLogs(params)
  ),
  loadMoreTransfersLogs: createAsyncThunk(
    '/kraken-logs/load-more',
    async (params: Record<string, any>) => await batchedTransfersLogsService.getTransfersLogs(params)
  ),
};
