import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// STORE
import organizationStore from '../store/reducers/organizations';
import { useAppSelector } from './redux.hook';

const useGetAllOrgs = (): null => {
  const dispatch = useDispatch();
  const admin = useAppSelector(state => state.admin.adminAuthed);
  const history = useHistory();
  const withoutAuthRoutes = ['/'];
  const allOrganizations = useAppSelector(state => state.organizations.allOrganizationsList);
  const organization = useAppSelector(state => state.organizations.organization);

  useEffect(() => {
    if (!admin || withoutAuthRoutes.includes(history.location.pathname)) return;

    if (!allOrganizations?.length && organization?.name === 'Jigzo') {
      dispatch(organizationStore.asyncActions.getAllOrganizations());
    }
  }, [admin, allOrganizations, organization?.name]);

  return null;
};

export default useGetAllOrgs;
