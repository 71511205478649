import { createSlice } from '@reduxjs/toolkit';
import { defaultPagination } from '../search/pagination.D';
import asyncActions from './asyncActions';
import { IBalancesSlice } from './index.D';

const initialState = {
  balances: {
    balances: [],
    pagination: defaultPagination,
    items: [],
    info: defaultPagination,
  },
  isLoading: false,
  error: '',
} as IBalancesSlice;

const balancesSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    clearBalances(state: IBalancesSlice) {
      state.balances = {
        balances: [],
        pagination: defaultPagination,

        items: [],
        info: defaultPagination,
      };

      return state;
    },
  },
  extraReducers: {
    [asyncActions.getCustomerBalances.fulfilled.type]: (state: IBalancesSlice, action) => {
      state.balances = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getBusinessBalances.fulfilled.type]: (state: IBalancesSlice, action) => {
      state.balances = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.demoFundingAccount.fulfilled.type]: (state: IBalancesSlice, action) => {
      const updatedBalances = state.balances.items.map(balance =>
        balance._id === action.payload._id
          ? {
              ...balance,
              balance: action.payload.balance,
              amount: Number(balance.amount) + Number(action.payload.fundAmount),
            }
          : balance
      );
      state.balances.items = updatedBalances;
      state.isLoading = false;
      state.error = '';
    },
  },
});

export default {
  ...balancesSlice,
  asyncActions,
};
