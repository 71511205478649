import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/admins',
    title: 'Admins',
    component: lazy(() => import('../../../components/views/Admins')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['admin_user_read'],
    routes: [],
  },
  {
    path: '/admins/create',
    title: 'Create admin',
    component: lazy(() => import('../../../components/modules/form/CreateAdminForm')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['admin_user_create'],
    routes: [],
  },
  {
    path: '/admin/me',
    title: 'Admin details',
    component: lazy(() => import('../../../components/modules/Admin/AdminAuthedData')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: [],
    // routes:      [
    // 	{
    // 		title:  'Admins',
    // 		path:   '/admin',
    // 		params: []
    // 	},
    // ]
  },
  {
    path: '/admins/:id',
    title: 'Admin details',
    component: lazy(() => import('../../../components/views/AdminDetail')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['admin_user_read'],
    routes: [
      {
        title: 'Admins',
        path: '/admins',
        params: [],
      },
    ],
  },
  {
    path: '/admins/:id/edit',
    title: 'Edit admin',
    component: lazy(() => import('../../../components/modules/form/AdminEditForm')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['admin_user_update', 'admin_user_delete'],
    routes: [
      {
        title: 'Admins',
        path: '/admins',
        params: [],
      },
      {
        title: 'Admin details',
        path: '/admins/:id',
        params: ['id'],
      },
    ],
  },
] as IRoutes[];
