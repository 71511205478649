// CORE
import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { ITransactionsCostsSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';
import { defaultPagination } from '../search/pagination.D';

const initialState: ITransactionsCostsSlice = [
  'freeMarketCosts',
  'krakenFiatCosts',
  'krakenCryptoCosts',
  'krakenOrdersCosts',
].reduce(
  (acc, currValue) => ({
    ...acc,
    [currValue]: {
      isLoading: false,
      fees: {
        items: [],
        info: defaultPagination,
      },
    },
  }),
  {} as ITransactionsCostsSlice
);

const transactionsCostsSlice = createSlice({
  name: 'transactionsCosts',
  initialState,
  reducers: {},
  extraReducers: {
    // FREE MARKET COSTS
    [asyncActions.readFreeMarketCosts.fulfilled.type]: (state, { payload }) => {
      state.freeMarketCosts.fees = payload;
      state.freeMarketCosts.isLoading = false;
    },
    [asyncActions.readFreeMarketCosts.pending.type]: state => {
      state.freeMarketCosts.isLoading = true;
    },
    [asyncActions.readFreeMarketCosts.rejected.type]: state => {
      state.freeMarketCosts.isLoading = false;
    },

    // KRAKEN FIAT COSTS
    [asyncActions.readKrakenFiat.fulfilled.type]: (state, { payload }) => {
      state.krakenFiatCosts.fees = payload;
      state.krakenFiatCosts.isLoading = false;
    },
    [asyncActions.readKrakenFiat.pending.type]: state => {
      state.krakenFiatCosts.isLoading = true;
    },
    [asyncActions.readKrakenFiat.rejected.type]: state => {
      state.krakenFiatCosts.isLoading = false;
    },

    // KRAKEN CRYPTO COSTS
    [asyncActions.readKrakenCrypto.fulfilled.type]: (state, { payload }) => {
      state.krakenCryptoCosts.fees = payload;
      state.krakenCryptoCosts.isLoading = false;
    },
    [asyncActions.readKrakenCrypto.pending.type]: state => {
      state.krakenCryptoCosts.isLoading = true;
    },
    [asyncActions.readKrakenCrypto.rejected.type]: state => {
      state.krakenCryptoCosts.isLoading = false;
    },

    // KRAKEN ORDERS COSTS
    [asyncActions.readKrakenOrders.fulfilled.type]: (state, { payload }) => {
      state.krakenOrdersCosts.fees = payload;
      state.krakenOrdersCosts.isLoading = false;
    },
    [asyncActions.readKrakenOrders.pending.type]: state => {
      state.krakenOrdersCosts.isLoading = true;
    },
    [asyncActions.readKrakenOrders.rejected.type]: state => {
      state.krakenOrdersCosts.isLoading = false;
    },
  },
});

export default {
  ...transactionsCostsSlice,
  asyncActions,
};
