// core
import { createAsyncThunk } from '@reduxjs/toolkit';

// helpers
import { storeAddErrorNotification } from '../../../helpers/errors';

// services
import riskAlertsService from '../../../services/riskAlerts';

export default {
  loadMoreTransactions: createAsyncThunk(
    '/risky-transactions/get',
    async (params: { [key: string]: string }) => await riskAlertsService.getRiskyTransactions(params)
  ),
  getTransactions: createAsyncThunk(
    '/risky-transactions/index',
    async (params: { [key: string]: string }) => await riskAlertsService.getRiskyTransactions(params)
  ),
  acceptRiskyTransaction: createAsyncThunk(
    '/risky-transaction/accept',
    async (alertId: string, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.accept(alertId, ''), thunkAPI, true)
  ),
  rejectRiskyTransaction: createAsyncThunk(
    '/risky-transaction/reject',
    async (alertId: string) => await riskAlertsService.reject(alertId, '')
  ),
};
