import { IForecastingDaxRawData } from '../index.D';

export const calculateRowResult = (data: IForecastingDaxRawData): number => {
  const countResult = +(data.brand.value * data.count).toFixed(2);

  const totalAmount = data.count * data.averageSum;
  const amountResult = +((totalAmount * data.brand.percent) / 100).toFixed(2);

  return countResult + amountResult;
};
