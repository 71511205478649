import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/usage',
    title: 'Usage',
    component: lazy(() => import('../../../components/views/Usage')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['reports_read'],
    routes: [],
  },
] as IRoutes[];
