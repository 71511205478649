// AXIOS
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import objectToURL from '../../helpers/objectToURL';

const { get, post } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default {
  getCards: (customerId: string): Promise<string> => get(`${URL()}/cards/customer/${customerId}`),
  getCardById: (id: string): Promise<string> => get(`${URL()}/cards/${id}`),
  getTransactionsBySan: (query: any): Promise<string> =>
    get(`${URL()}/cards/transaction-history?${objectToURL(query)}`),
  getCardCurrencies: (): Promise<string> => get(`${URL()}/cards/currencies`),
  findCards: (params?: { [k: string]: any }) => get(`${URL()}/cards/find?${objectToURL(params)}`),
  uploadCards: (data: FormData) => post(`${URL()}/cards/upload`, data),
  postCard: (id: string) => get(`${URL()}/cards/${id}/post`),
};
