// AXIOS
import { apiClient } from '../../clients/api.client';
// TYPES
import { IGetBeneficiaries } from '../../store/reducers/beneficiaries/index.D';
// HELPERS
import objectToURL from '../../helpers/objectToURL';
import getBrand from '../../helpers/getBrand';

const { get, delete: del } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default {
  getBeneficiary: (beneficiaryId: string) => get(`${URL()}/beneficiaries/${beneficiaryId}`),
  getBeneficiaryCC: (beneficiaryCC: string) => get(`${URL()}/beneficiaries/beneficiaryCC/${beneficiaryCC}`),
  deleteBeneficiary: (beneficiaryId: string) => del(`${URL()}/beneficiaries/${beneficiaryId}`),
  getBusinessBeneficiaries: ({ id, params }: IGetBeneficiaries): Promise<string> =>
    get(`${URL()}/business/${id}/beneficiaries?${objectToURL(params)}`),
};
