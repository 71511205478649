import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/risk-rules',
    title: 'Risk Rules',
    component: lazy(() => import('../../../components/views/ManageCompliance')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['risk_rules_read'],
    routes: [],
  },
  {
    path: '/risk-rules/:id',
    title: 'Risk Rules Details',
    component: lazy(() => import('../../../components/views/ManageComplianceDetail')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['risk_rules_read'],
    routes: [
      {
        path: '/risk-rules',
        title: 'Risk Rules',
        params: [],
      },
    ],
  },
  {
    path: '/risk-rules/rule/create',
    title: 'Risk Rule Create',
    component: lazy(() => import('../../../components/views/ManageComplianceCreate')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['risk_rules_create'],
    routes: [
      {
        path: '/risk-rules',
        title: 'Risk Rules',
        params: [],
      },
    ],
  },
] as IRoutes[];
