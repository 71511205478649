// CORE
import { ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
// TYPES
import { IRoutes } from '../routes/routes.D';
// HOOKS
import { useAppSelector } from '../hooks/redux.hook';

const PublicRoutes = (route: IRoutes): ReactElement => {
  const isAuth = useAppSelector(({ admin }) => admin?.adminAuthed);

  if (!isAuth && route.private) return <Redirect to={{ pathname: '/' }} />;

  return (
    <Route
      path={route.path}
      key={route.path}
      render={props => (
        <route.layout>
          <route.component {...props} />
        </route.layout>
      )}
    />
  );
};

export default PublicRoutes;
