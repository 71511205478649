import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeAddErrorNotification } from '../../../helpers/errors';
import { IDocument, IParams } from './index.D';
import servicesDocuments from '../../../services/documents';

export default {
  getAllDocuments: createAsyncThunk(
    'documents/getAllDocuments',
    async (params: IParams, thunkAPI) =>
      await storeAddErrorNotification(() => servicesDocuments.getAllDocuments(params), thunkAPI, false)
  ),
  getByCustomer: createAsyncThunk(
    'documents/getByCustomer',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesDocuments.getByCustomer(params), thunkAPI, false)
  ),
  getCustomerDocuments: createAsyncThunk(
    'documents/getCustomerDocuments',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesDocuments.getByCustomer(params), thunkAPI, false)
  ),
  readDocument: createAsyncThunk(
    'document/get',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesDocuments.read(params), thunkAPI, false)
  ),
  deleteDocument: createAsyncThunk(
    'documents/deleteDocument',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesDocuments.deleteDocument(params), thunkAPI, false)
  ),
  deleteManyDocuments: createAsyncThunk(
    'documents/deleteManyDocuments',
    async (params: Partial<IDocument>[], thunkAPI) =>
      await storeAddErrorNotification(() => servicesDocuments.deleteManyDocuments(), thunkAPI, false)
  ),
  updateDocument: createAsyncThunk(
    'documents/updateDocument',
    async (params: Partial<IDocument>, thunkAPI) =>
      await storeAddErrorNotification(() => servicesDocuments.updateDocument(params), thunkAPI, false)
  ),
  downloadDocument: createAsyncThunk(
    'documents/downloadDocument',
    async (params: IParams, thunkAPI) =>
      await storeAddErrorNotification(() => servicesDocuments.downloadDocument(params), thunkAPI, false)
  ),
  viewDocument: createAsyncThunk(
    'documents/viewDocument',
    async (params: IParams, thunkAPI) =>
      await storeAddErrorNotification(() => servicesDocuments.viewDocument(params), thunkAPI, false)
  ),
  uploadBusinessDocument: createAsyncThunk(
    'documents/uploadBusinessDocument',
    async ({ id, data }: { id: string; data: FormData }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesDocuments.uploadBusinessDocument(id, data), thunkAPI, false)
  ),
  uploadCustomerDocument: createAsyncThunk(
    'documents/uploadCustomerDocument',
    async ({ id, data }: { id: string; data: FormData }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesDocuments.uploadCustomerDocument(id, data), thunkAPI, false)
  ),
};
