import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeAddErrorNotification } from '../../../helpers/errors';
import servicesBeneficiary from '../../../services/beneficiary';

export default {
  getBeneficiary: createAsyncThunk(
    'beneficiaries/getBeneficiary',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBeneficiary.getBeneficiary(params), thunkAPI, false)
  ),
  getBeneficiaryCC: createAsyncThunk(
    'beneficiaries/getBeneficiaryCC',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBeneficiary.getBeneficiaryCC(params), thunkAPI, false)
  ),
};
