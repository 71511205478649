// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
import objectToURL from '../../helpers/objectToURL';
// TYPES
import { ICreatePricePlanPayload } from '../../store/reducers/pricePlans/index.D';

const { post, get, put, delete: del } = apiClient;

const URL = () => `organizations/${getBrand()}/price-plans`;

export default {
  createPricePlan: (data: ICreatePricePlanPayload) => post(URL(), data),
  getPricePlans: (params: Record<string, string | number>) => get(`${URL()}/?${objectToURL(params)}`),
  getPricePlansList: () => get(`${URL()}/list`),
  updatePricePlan: ({ id, data }: { id: string; data: Partial<ICreatePricePlanPayload> }) =>
    put(`${URL()}/${id}`, data),
  // deletePricePlan: (id: string) => del(`${URL()}/${id}`),
};
