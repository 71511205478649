import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { IAnnouncementsSlice } from './index.D';
// ASYNC ACTIONS
import asyncActions from './asyncActions';

const initialState: IAnnouncementsSlice = {
  isLoading: false,
};

const announcementSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {},
  extraReducers: {
    [asyncActions.sendGlobalAnnouncement.pending.type]: state => {
      state.isLoading = true;
    },
    [asyncActions.sendGlobalAnnouncement.rejected.type]: state => {
      state.isLoading = false;
    },
    [asyncActions.sendGlobalAnnouncement.fulfilled.type]: (state, action) => {
      console.log({ action });
      state.isLoading = false;
    },
  },
});

export default {
  ...announcementSlice,
  asyncActions,
};
