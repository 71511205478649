// CORE
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// HELPERS
import { defaultPagination } from '../search/pagination.D';
// ACTIONS
import asyncActions from './asyncActions';
// TYPES
import {
  IDashboardSlice,
  IDashboardCustomersRisks,
  IDashboardCustomersItemChart,
  IDashboardCustomersItemCountry,
  IDashboardVolumesItemCurrency,
  IDashboardVolumesItemChart,
  IDashboarEarningsItemChart,
} from './index.D';

const initialState = {
  customers: {
    items: [],
    chartData: [],
    countriesData: [],
    riskData: { 1: 0, 2: 0, 3: 0 },
    count: 0,
  },
  earnings: {
    transactions: {},
    items: [],
    chartData: [],
  },
  volumes: {
    items: [],
    totalAmount: 0,
    volumesCurrency: [],
    chartData: [],
  },
  transactions: {
    items: [],
    info: defaultPagination,
  },
} as IDashboardSlice;

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearData: (state: IDashboardSlice) => {
      state = initialState;
    },
    setCustomerRisks: (state, action: PayloadAction<IDashboardCustomersRisks>) => {
      state.customers.riskData = action.payload;
    },
    setCustomerChart: (state, action: PayloadAction<IDashboardCustomersItemChart[]>) => {
      state.customers.chartData = action.payload;
    },
    setCustomerCountry: (state, action: PayloadAction<IDashboardCustomersItemCountry[]>) => {
      state.customers.countriesData = action.payload;
    },
    setCustomerCount: (state, action: PayloadAction<number>) => {
      state.customers.count = action.payload;
    },
    setVolumesTotalAmount: (state, action: PayloadAction<number>) => {
      state.volumes.totalAmount = action.payload;
    },
    setVolumesByCurrency: (state, action: PayloadAction<IDashboardVolumesItemCurrency[]>) => {
      state.volumes.volumesCurrency = action.payload;
    },
    setVolumesChartData: (state, action: PayloadAction<IDashboardVolumesItemChart[]>) => {
      state.volumes.chartData = action.payload;
    },
    setEarningsChartData: (state, action: PayloadAction<IDashboarEarningsItemChart[]>) => {
      state.earnings.chartData = action.payload;
    },
  },
  extraReducers: {
    [asyncActions.getDashboardCustomers.fulfilled.type]: (state, action) => {
      state.customers.items = action.payload;
    },

    [asyncActions.getDashboardVolumes.fulfilled.type]: (state, action) => {
      state.volumes.items = action.payload;
    },
    [asyncActions.getDashboardTransactions.fulfilled.type]: (state, action) => {
      state.transactions.items = action.payload;
    },
    [asyncActions.getDashboardEarnings.fulfilled.type]: (state, action) => {
      state.earnings.items = action.payload.items;
      state.earnings.transactions = action.payload.transactions;
    },
  },
});

export default {
  ...dashboardSlice,
  asyncActions,
};
