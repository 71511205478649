// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import logService from '../../../services/logs';

export default {
  getLogs: createAsyncThunk(
    'logs/get',
    async (params: { [key: string]: string }, thunkAPI) =>
      await storeAddErrorNotification(() => logService.getLogs(params), thunkAPI, false)
  ),
  getLogById: createAsyncThunk(
    'log/getById',
    async (id: string, thunkAPI) => await storeAddErrorNotification(() => logService.getLogById(id), thunkAPI, false)
  ),
  deleteLog: createAsyncThunk(
    'log/delete',
    async (id: string, thunkAPI) => await storeAddErrorNotification(() => logService.deleteLog(id), thunkAPI, false)
  ),
};
