// AXIOS
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { IQuestionnaireSettings } from '../../store/reducers/questionnaire/types';

const { get, put } = apiClient;

const URL = () => `organizations/${getBrand(true)}/questionnaire`;

export default {
  getQuestionnaireSettings: (): Promise<any> => get(`${URL()}/`),
  updateQuestionnaireSettings: (data: IQuestionnaireSettings): Promise<IQuestionnaireSettings> =>
    put(`${URL()}/`, data),
};
