import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeAddErrorNotification } from '../../../helpers/errors';
import reportsService from '../../../../app/services/reports';

export default {
  getDashboardCustomers: createAsyncThunk(
    'dashboard/customers',
    async (params: Record<string, unknown>, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.getDashboardCustomers(params), thunkAPI, false)
  ),
  getDashboardVolumes: createAsyncThunk(
    'dashboard/volumes',
    async (params: Record<string, unknown>, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.getDashboardVolumes(params), thunkAPI, false)
  ),
  getDashboardEarnings: createAsyncThunk(
    'dashboard/earnings',
    async (params: Record<string, unknown>, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.getDashboardEarnings(params), thunkAPI, false)
  ),
  getDashboardTransactions: createAsyncThunk(
    'dashboard/transactions',
    async (params: Record<string, unknown>, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.getDashboardTransactions(params), thunkAPI, false)
  ),
};
