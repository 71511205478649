// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import service from '../../../services/questionnaire';
// TYPES
import { IQuestionnaireSettings } from './types';

export default {
  get: createAsyncThunk(
    'questionnaire/get',
    async (_, thunkAPI) => await storeAddErrorNotification(() => service.getQuestionnaireSettings(), thunkAPI, false)
  ),
  update: createAsyncThunk(
    'questionnaire/update',
    async (data: IQuestionnaireSettings, thunkAPI) =>
      await storeAddErrorNotification(() => service.updateQuestionnaireSettings(data), thunkAPI, false)
  ),
};
