// AXIOS
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
import objectToURL from '../../helpers/objectToURL';
// TYPES
import { ICreateDepositPayload } from '../../store/reducers/manualTransactions/index.D';

const { get, put, post, patch } = apiClient;

const URL = () => `organizations/${getBrand()}/manual-transactions`;

export default {
  createDeposit: (data: ICreateDepositPayload) => post(`${URL()}/deposits`, data),
  getWithdrawals: (params: Record<string, string | number>) => get(`${URL()}/withdrawals?${objectToURL(params)}`),
  updateWithdrawal: ({ id, status }: { id: string; status: number }) => put(`${URL()}/withdrawals/${id}`, { status }),
  updateManyWithdrawal: (data: { ids: string[]; status: number }) => patch(`${URL()}/withdrawals`, data),
  sendDepositBack: (data: { transferId: string }) => get(`${URL()}/deposits/${data.transferId}/send-back`),
};
