// API
import { apiClient } from 'app/clients/api.client';
// HELPERS
import getBrand from 'app/helpers/getBrand';
// TYPES
import { IUpdatePrimaryCurrency, OrganizationSettings } from 'app/store/reducers/organizationSettings/index.D';

type PrimaryCurrencyResult = Promise<OrganizationSettings['primaryCurrency']>;

const { get, put, patch } = apiClient;

const URL = () => `organizations/${getBrand()}/settings`;

export default {
  getPrimaryCurrency: (): PrimaryCurrencyResult => get(`${URL()}/primary-currency`),
  updatePrimaryCurrency: (data: IUpdatePrimaryCurrency): PrimaryCurrencyResult => put(URL(), data),
  read: (): Promise<OrganizationSettings> => get(URL()),
  updatePartial: (data: Partial<OrganizationSettings>): Promise<OrganizationSettings> => patch(URL(), data),
};
