import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
import { IApiKeySlice } from './index.D';

const initialState = {
  apiKey: {
    whiteList: [],
    _id: '',
    auth: '',
    apiKey: '',
    createdAt: '',
    updatedAt: '',
  },
  isLoading: false,
  error: '',
};

const apiKeySlice = createSlice({
  name: 'apiKey',
  initialState,
  reducers: {},
  extraReducers: {
    [asyncActions.getApiKey.fulfilled.type]: (state: IApiKeySlice, action) => {
      state.apiKey = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.createApiKey.fulfilled.type]: (state: IApiKeySlice, action) => {
      state.apiKey = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.updateApiKey.fulfilled.type]: (state: IApiKeySlice, action) => {
      state.apiKey = action.payload;
      state.isLoading = false;
      state.error = '';
    },
  },
});

export default {
  ...apiKeySlice,
  asyncActions,
};
