import { ICustomer } from '../customers/index.D';
import { ICurrency } from '../currencies/index.D';
import { IPagination } from '../search/pagination.D';
import { IBusiness, IBusinessContact } from '../business/index.D';

export enum CardStatus {
  Unregistered = 'Unregistered',
  Pending = 'Pending',
  Posted = 'Posted',
  Active = 'Active',
  Blocked = 'Blocked',
}

export enum CardStatusIC {
  Unknown = 'Unknown',
  Suspend = 'Suspend',
  Normal = 'Normal',
  Inactive = 'Inactive',
  Lost = 'Lost',
  Stolen = 'Stolen',
  Expired = 'Expired',
}

export interface ICard {
  _id: string;
  san: string;
  amount: number;
  currencyCode?: string;
  customer?: ICustomer;
  status?: CardStatus;
  statusIC?: CardStatusIC;
  createdAt: string;
  assignedAt?: string;
  business?: IBusiness;
  currency?: ICurrency;
  businessContact?: IBusinessContact;
}

export interface cardSlice {
  cards: IPagination<ICard>;
  card: ICard | null;
  currencies: ICurrency[];
  transactions: any[];
  isLoading: boolean;
  error: string;
}
