// TYPES
import { forecastDaxOperation } from '../../../../app/store/reducers/forecastingDax/index.D';

export const receiveOperations = [
  forecastDaxOperation.sepaReceive,
  forecastDaxOperation.fasterReceive,
  forecastDaxOperation.swiftReceive,
  forecastDaxOperation.chapsReceive,
  forecastDaxOperation.targetReceive,
];

export const sendOperations = [
  forecastDaxOperation.sepaSend,
  forecastDaxOperation.fasterSend,
  forecastDaxOperation.swiftSend,
  forecastDaxOperation.chapsSend,
  forecastDaxOperation.targetSend,
];

export const convertOperations = [
  forecastDaxOperation.eurToCrypto,
  forecastDaxOperation.gbpToCrypto,
  forecastDaxOperation.cryptoToEur,
  forecastDaxOperation.cryptoToGbp,
];

export const regularOperations = [
  forecastDaxOperation.balanceEur,
  forecastDaxOperation.balanceGbp,
  forecastDaxOperation.subscriptionEur,
  forecastDaxOperation.subscriptionGbp,
  forecastDaxOperation.opening,
];

export const operationsOrder: Record<forecastDaxOperation, number> = {
  // PAYMENTS
  [forecastDaxOperation.sepaReceive]: 0,
  [forecastDaxOperation.sepaSend]: 0,
  [forecastDaxOperation.targetReceive]: 1,
  [forecastDaxOperation.targetSend]: 1,
  [forecastDaxOperation.fasterReceive]: 2,
  [forecastDaxOperation.fasterSend]: 2,
  [forecastDaxOperation.chapsReceive]: 3,
  [forecastDaxOperation.chapsSend]: 3,
  [forecastDaxOperation.swiftReceive]: 4,
  [forecastDaxOperation.swiftSend]: 4,
  // CONVERSIONS
  [forecastDaxOperation.eurToCrypto]: 0,
  [forecastDaxOperation.cryptoToEur]: 1,
  [forecastDaxOperation.gbpToCrypto]: 2,
  [forecastDaxOperation.cryptoToGbp]: 3,
  // NEGATIVE INTEREST
  [forecastDaxOperation.balanceEur]: 0,
  [forecastDaxOperation.balanceGbp]: 1,
  // SUBSCRIPTION
  [forecastDaxOperation.subscriptionEur]: 2,
  [forecastDaxOperation.subscriptionGbp]: 3,
  // OPENING
  [forecastDaxOperation.opening]: 4,
};
