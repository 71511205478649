import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// TYPES
import { IReportsSlice, IForecastData, IReportTransfersData, IFeeMarginReport } from './index.D';

const initialForecastState = {
  users: 0,
  transactions: {},
  data: {},
} as IForecastData;

const initialTransfersReport = {
  maxAmount: 0,
  maxCount: 0,
  amountList: [],
  countList: [],
} as IReportTransfersData;

const initialFeeMarginState = {
  lastMonth: 0,
  allTime: 0,
  maxAmount: 0,
  list: [],
} as IFeeMarginReport;

const initialState: IReportsSlice = {
  monthlyFees: [],
  monthlyFeesReport: initialFeeMarginState,
  margins: initialFeeMarginState,
  fees: initialFeeMarginState,
  revenueShare: {
    revenueShareValue: 0.5,
    transfers: [],
    monthlyFees: [],
  },
  payments: initialTransfersReport,
  conversions: initialTransfersReport,
  funds: initialTransfersReport,
  conversionsSell: initialTransfersReport,
  cardLoads: initialTransfersReport,
  forecast: initialForecastState,
  isLoading: false,

  cryptoReports: {},
};

const rolesSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: {
    [asyncActions.findLatestMonthlyFees.fulfilled.type]: (state, action) => {
      state.monthlyFees = action.payload;
      state.isLoading = false;
    },
    [asyncActions.findMonthlyFees.fulfilled.type]: (state, action) => {
      state.monthlyFees = action.payload;
      state.isLoading = false;
    },
    [asyncActions.getMonthlyFeesReport.fulfilled.type]: (state, action) => {
      state.monthlyFeesReport = action.payload;
      state.isLoading = false;
    },
    [asyncActions.findRevenueShare.fulfilled.type]: (state, action) => {
      state.revenueShare = action.payload;
      state.isLoading = false;
    },
    [asyncActions.findFees.fulfilled.type]: (state, action) => {
      state.fees = action.payload;
      state.isLoading = false;
    },
    [asyncActions.findMargins.fulfilled.type]: (state, action) => {
      state.margins = action.payload;
      state.isLoading = false;
    },
    [asyncActions.updateMonthlyFees.fulfilled.type]: (state, action) => {
      state.monthlyFees = state.monthlyFees.map(fee => {
        if (fee._id === action.payload._id) return action.payload;

        return fee;
      });
      state.isLoading = false;
    },
    [asyncActions.createMonthlyFees.fulfilled.type]: (state, action) => {
      state.monthlyFees = [...state.monthlyFees, action.payload];
      state.isLoading = false;
    },
    [asyncActions.findPayments.fulfilled.type]: (state, action) => {
      state.payments = action.payload;
      state.isLoading = false;
    },
    [asyncActions.findConversions.fulfilled.type]: (state, action) => {
      state.conversions = action.payload;
      state.isLoading = false;
    },
    [asyncActions.findConversionsSell.fulfilled.type]: (state, action) => {
      state.conversionsSell = action.payload;
      state.isLoading = false;
    },
    [asyncActions.findFunds.fulfilled.type]: (state, action) => {
      state.funds = action.payload;
      state.isLoading = false;
    },
    [asyncActions.findCardLoads.fulfilled.type]: (state, action) => {
      state.cardLoads = action.payload;
      state.isLoading = false;
    },
    [asyncActions.getCryptoReconciliationReport.fulfilled.type]: (state, action) => {
      state.cryptoReports.reconciliationReports = action.payload;
      state.isLoading = false;
    },
    [asyncActions.getCryptoToFiatReport.fulfilled.type]: (state, action) => {
      state.cryptoReports.cryptoToFiatReport = action.payload;
      state.isLoading = false;
    },
    [asyncActions.getFiatToCryptoReport.fulfilled.type]: (state, action) => {
      state.cryptoReports.fiatToCryptoReport = action.payload;
      state.isLoading = false;
    },
    [asyncActions.getMarginReport.fulfilled.type]: (state, action) => {
      state.cryptoReports.marginReport = action.payload;
      state.isLoading = false;
    },
    [asyncActions.getFeesReport.fulfilled.type]: (state, action) => {
      state.cryptoReports.feesReport = action.payload;
      state.isLoading = false;
    },
  },
});

export default {
  ...rolesSlice,
  asyncActions,
};
