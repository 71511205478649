// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import servicesBeneficiaries from '../../../services/beneficiaries';
import servicesBeneficiary from '../../../services/beneficiary';
// TYPES
import { IGetBeneficiaries } from './index.D';

export default {
  getBeneficiaries: createAsyncThunk(
    'recipients/getBeneficiaries',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBeneficiaries.getBeneficiaries(), thunkAPI, false)
  ),
  deleteBeneficiary: createAsyncThunk(
    'customers/deleteCustomer',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBeneficiary.deleteBeneficiary(id), thunkAPI, false)
  ),
  getBusinessBeneficiaries: createAsyncThunk(
    'business/getBusinessBeneficiaries',
    async (data: IGetBeneficiaries, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBeneficiary.getBusinessBeneficiaries(data), thunkAPI, false)
  ),
  getBeneficiariesByCustomer: createAsyncThunk(
    'recipients/getBeneficiariesByCustomer',
    async (data: IGetBeneficiaries, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBeneficiaries.getBeneficiariesByCustomer(data), thunkAPI, false)
  ),
};
