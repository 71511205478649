// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand, { isJigzoBrand } from '../../helpers/getBrand';
import objectToURL from '../../helpers/objectToURL';
// TYPES
import {
  ICryptoMarginFeeCreateData,
  ICryptoRegularFeeCreateData,
} from '../../store/reducers/cryptoFeesMargins/index.D';

const { get, delete: del, post, patch } = apiClient;
const URL = () => `organizations/${getBrand(isJigzoBrand())}/crypto-margins-fees`;

export default {
  getPlatformFees: (params: Record<string, unknown>) => get(`${URL()}/platform?${objectToURL(params)}`),
  createPlatformFee: (data: ICryptoMarginFeeCreateData) => post(`${URL()}/platform`, data),

  getConversionGlobal: (params: Record<string, unknown>) => get(`${URL()}/conversion/global?${objectToURL(params)}`),
  createConversionGlobal: (data: ICryptoMarginFeeCreateData) => post(`${URL()}/conversion/global`, data),

  getConversionPairs: (params: Record<string, unknown>) => get(`${URL()}/conversion/pairs?${objectToURL(params)}`),
  createConversionPair: (data: ICryptoMarginFeeCreateData) => post(`${URL()}/conversion/pairs`, data),

  updateMarginFee: ({ id, data }: { data: ICryptoMarginFeeCreateData; id: string }) => patch(`${URL()}/${id}`, data),
  deleteMarginFee: (id: string) => del(`${URL()}/${id}`),

  getBalanceFees: (params: Record<string, unknown>) => get(`${URL()}/regular?${objectToURL({ ...params, type: 0 })}`),
  getMaintenanceFees: (params: Record<string, unknown>) =>
    get(`${URL()}/regular?${objectToURL({ ...params, type: 1 })}`),
  createRegularFee: (data: ICryptoRegularFeeCreateData) => post(`${URL()}/regular`, data),
  updateRegularFee: ({ id, data }: { data: ICryptoRegularFeeCreateData; id: string }) =>
    patch(`${URL()}/regular/${id}`, data),
  deleteRegularFee: (id: string) => del(`${URL()}/regular/${id}`),
};
