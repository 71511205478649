import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';
import { orgTypes } from '../../../app/store/reducers/organizations/index.D';

export default [
  {
    path: '/transactions-costs',
    title: 'Transactions costs',
    component: lazy(() => import('../../../components/views/TransactionsCosts')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['service_costs_read'],
    routes: [],
    orgTypes: [orgTypes.master],
  },
] as IRoutes[];
