import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { ICountriesSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState = {
  countries: {
    items: [],
    info: {
      current_page: 1,
      next_page: null,
      order: null,
      order_asc_desc: 'asc',
      per_page: 10,
      previous_page: null,
      total_entries: 0,
      total_pages: 1,
    },
  },
  list: [],
  isLoading: false,
} as ICountriesSlice;

const currenciesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    clearCountries(state: ICountriesSlice) {
      state.countries = {
        items: [],
        info: {
          current_page: 1,
          next_page: null,
          order: null,
          order_asc_desc: 'asc',
          per_page: 10,
          previous_page: null,
          total_entries: 0,
          total_pages: 1,
        },
      };

      return state;
    },
  },
  extraReducers: {
    [asyncActions.getCountries.fulfilled.type]: (state: ICountriesSlice, action) => {
      state.countries = action.payload;
      state.isLoading = false;
    },
    [asyncActions.getCountries.pending.type]: (state: ICountriesSlice) => {
      state.isLoading = true;
    },
    [asyncActions.getCountries.rejected.type]: (state: ICountriesSlice) => {
      state.isLoading = false;
    },
    [asyncActions.findAllCountries.fulfilled.type]: (state: ICountriesSlice, action) => {
      state.list = action.payload;
      state.isLoading = false;
    },
  },
});

export default {
  ...currenciesSlice,
  asyncActions,
};
