// core
import { createAsyncThunk } from '@reduxjs/toolkit';

// services
import batchedTransfersService from '../../../services/batchedTransfers';

export default {
  getBatchedTransfers: createAsyncThunk(
    '/batched-transfers/get',
    async (params: Record<string, any>) => await batchedTransfersService.getBatchedTransfers(params)
  ),
};
