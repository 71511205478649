// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import limitService from '../../../services/limits';
// TYPES
import { ITransactionData } from './index.D';

export default {
  getLimits: createAsyncThunk(
    'limits/get',
    async (params, thunkAPI) => await storeAddErrorNotification(() => limitService.getLimits(), thunkAPI, false)
  ),
  createLimit: createAsyncThunk(
    'limit/post',
    async (data: ITransactionData, thunkAPI) =>
      await storeAddErrorNotification(() => limitService.create(data), thunkAPI, false)
  ),
  updateLimit: createAsyncThunk(
    'limit/put',
    async (data: { id: string; data: ITransactionData }, thunkAPI) =>
      await storeAddErrorNotification(() => limitService.update(data), thunkAPI, false)
  ),
};
