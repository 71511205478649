import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/reports',
    title: 'Revenue Reports',
    component: lazy(() => import('../../../components/views/Reports')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['reports_read'],
    routes: [],
  },
  {
    path: '/crypto-reporting',
    title: 'Crypto Reporting',
    component: lazy(() => import('../../../components/views/CryptoReporting')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['reports_read'],
    routes: [],
  },
] as IRoutes[];
