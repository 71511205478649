// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import freeMarketService from '../../../services/freeMarket';
// TYPES
import { IAssignAccountPayload } from './index.D';

export default {
  assignFreeMarketAccount: createAsyncThunk(
    'freeMarket/assignAccount',
    async (data: IAssignAccountPayload, thunkAPI) =>
      await storeAddErrorNotification(() => freeMarketService.assignFreeMarketAccount(data), thunkAPI)
  ),
  getAccountReference: createAsyncThunk(
    'freeMarket/getAccountReference',
    async (customerId: string, thunkAPI) =>
      await storeAddErrorNotification(() => freeMarketService.getAccountReference(customerId), thunkAPI)
  ),
};
