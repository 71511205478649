export const getAccessToken = () => localStorage.getItem('access_token');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const setAccessToken = (token: string) => token && localStorage.setItem('access_token', token);

export const setRefreshToken = (token: string) => token && localStorage.setItem('refresh_token', token);

export const getSessionTime = () => localStorage.getItem('session_time');

export const setTempToken = (token: string) => token && localStorage.setItem('tempToken', token);

export const removeTempSession = () => localStorage.removeItem('tempToken');

export const setSessionTime = (time: number) => {
  if (time) {
    const expiryDate = Date.now() + time;
    localStorage.setItem('session_time', JSON.stringify(expiryDate));
  }
};

export const setIdleTimeout = (time: number) => {
  if (time) {
    localStorage.setItem('idleTimeout', time.toString());
  }
};

export const removeRefreshToken = () => {
  localStorage.removeItem('brand');
  localStorage.removeItem('session_time');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('access_token');
};

export const removeActivityData = () => {
  localStorage.removeItem('currentTime');
  localStorage.removeItem('lastActivityTime');
  localStorage.removeItem('inactive');
  localStorage.removeItem('showInactiveModal');
  localStorage.removeItem('idleTimeout');
};
