// AXIOS
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { IBankDetails, ICreateBankDetailsPayload } from '../../store/reducers/bankDetails/index.D';

const { get, put, post } = apiClient;

const URL = () => `organizations/${getBrand()}/bank-details`;

export default {
  getBankDetails: () => get(URL()),
  updateBankDetails: (id: string, data: Partial<IBankDetails>) => put(`${URL()}/${id}`, data),
  createBankDetails: (data: Partial<IBankDetails>) => post(URL(), data),
  createCustomBankDetails: (data: Partial<ICreateBankDetailsPayload>[]) => post(`${URL()}/custom`, data),
};
