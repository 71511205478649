import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeAddErrorNotification } from '../../../helpers/errors';
import servicesAdmins from '../../../services/admins';
import { IAdminUpdate, IAdminCreate } from '../admin/index.D';

export default {
  getAdmin: createAsyncThunk(
    'admins/getAdmin',
    async (id: string, thunkAPI) => await storeAddErrorNotification(() => servicesAdmins.getAdmin(id), thunkAPI, false)
  ),
  getAdmins: createAsyncThunk(
    'admins/getAdmins',
    async (params: { [k: string]: any } | undefined, thunkAPI) =>
      await storeAddErrorNotification(() => servicesAdmins.getAdmins(params), thunkAPI, false)
  ),
  deleteAdmin: createAsyncThunk(
    'admins/deleteAdmin',
    async (contactId: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesAdmins.deleteAdmin(contactId), thunkAPI, false)
  ),
  updateAdmin: createAsyncThunk(
    'admins/updateAdmin',
    async (params: Partial<IAdminUpdate>, thunkAPI) =>
      await storeAddErrorNotification(() => servicesAdmins.updateAdmin(params), thunkAPI, false)
  ),
  createAdmin: createAsyncThunk(
    'admins/createAdmin',
    async (params: IAdminCreate, thunkAPI) =>
      await storeAddErrorNotification(() => servicesAdmins.createAdmin(params), thunkAPI, false)
  ),
  resetPassword: createAsyncThunk(
    'admins/resetPassword',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesAdmins.resetPassword(id), thunkAPI, false)
  ),
  checkEmail: createAsyncThunk(
    'admins/checkEmail',
    async (email: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesAdmins.checkEmail(email), thunkAPI, false)
  ),
  uploadAvatar: createAsyncThunk(
    'admins/uploadAvatar',
    async ({ adminId, data }: { adminId: string; data: FormData }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesAdmins.uploadAvatar(adminId, data), thunkAPI)
  ),
};
