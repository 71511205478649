// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';

const { get } = apiClient;
const URL = () => `organizations/${getBrand()}`;

const pooledAccountsService = {
  getPooledAccounts: (customerID: string): Promise<string> => get(`${URL()}/pooled-accounts/list/${customerID}`),
};

export default pooledAccountsService;
