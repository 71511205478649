import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { INoteSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState = {
  items: [],
  isLoading: false,
} as INoteSlice;

const noteSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    clearNotes(state: INoteSlice) {
      state.items = [];

      return state;
    },
  },
  extraReducers: {
    [asyncActions.findNotesByModel.fulfilled.type]: (state: INoteSlice, action) => {
      state.items = action.payload;
      state.isLoading = false;
    },
    [asyncActions.createNote.fulfilled.type]: (state: INoteSlice, action) => {
      state.items = [action.payload, ...state.items];
      state.isLoading = false;
    },
  },
});

export default {
  ...noteSlice,
  asyncActions,
};
