import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
// STORE
import store from './app/store';
// ROUTES
import Routes from './app/routes';
// STYLES
import { theme } from './app/style/theme';
// COMPONENTS
import Notifier from './components/modules/Notifier';
import OnConnect from './onConnect';
import AuthActivity from './AuthActivity';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={4}>
          <OnConnect>
            <>
              <AuthActivity />
              <Router>
                <Routes />
              </Router>
            </>
          </OnConnect>
          <Notifier />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
