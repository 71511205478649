// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
import objectToURL from '../../helpers/objectToURL';

const URL = () => `organizations/${getBrand()}/registration-requests`;

const { get, post } = apiClient;

export default {
  getRegistrationRequests: (params?: Record<string, string | number>) => get(`${URL()}?${objectToURL(params)}`),
  getRegistrationRequestById: (id: string) => get(`${URL()}/${id}`),

  requestPayment: (id: string, link: string) => post(`${URL()}/${id}/request-payment`, { link }),
  reject: (id: string) => post(`${URL()}/${id}/reject`),
  continue: (id: string) => post(`${URL()}/${id}/continue`),
};
