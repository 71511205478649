import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';
import { orgTypes } from '../../../app/store/reducers/organizations/index.D';

export default [
  {
    path: '/batched-transfers',
    title: 'Batched Transfers',
    component: lazy(() => import('../../../components/views/BatchedTransfers')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['bulk_transfers_read'],
    routes: [],
    orgTypes: [orgTypes.DAX],
  },
  {
    path: '/batched-transfers/:transferId',
    title: 'Batched Transfers',
    component: lazy(() => import('../../../components/modules/BatchedTransfers/BatchedTransferDetails')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['bulk_transfers_read'],
    routes: [
      {
        title: 'Batched Transfers',
        path: '/batched-transfers',
        params: [],
      },
    ],
    orgTypes: [orgTypes.DAX],
  },
] as IRoutes[];
