// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import servicesTransfers from '../../../services/transfers';
// TYPES
import { IGetTransfersByCustomer } from './index.D';

export default {
  getTransfer: createAsyncThunk(
    'transfers/getTransfer',
    async (params: any, thunkAPI) =>
      await storeAddErrorNotification(() => servicesTransfers.getTransfer(params), thunkAPI, false)
  ),
  findTransfers: createAsyncThunk(
    'transfers/findTransfers',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesTransfers.findTransfers(params), thunkAPI, false)
  ),
  findAllTransfers: createAsyncThunk(
    'transfers/findAllTransfers',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(() => servicesTransfers.findAllTransfers(), thunkAPI, false)
  ),
  getTransfers: createAsyncThunk(
    'transfers/getTransfers',
    async (params: { [k: string]: any } | undefined, thunkAPI) =>
      await storeAddErrorNotification(() => servicesTransfers.getTransfers(params), thunkAPI, false)
  ),
  getTransfersByCustomer: createAsyncThunk(
    'transfers/getTransfersByCustomer',
    async (data: IGetTransfersByCustomer, thunkAPI) =>
      await storeAddErrorNotification(() => servicesTransfers.getTransfersByCustomer(data), thunkAPI, false)
  ),
};
