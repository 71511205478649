import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// STORE
import organizationStore from '../store/reducers/organizations';
import { useAppSelector } from './redux.hook';

const useGetHosts = (): null => {
  const dispatch = useDispatch();
  const admin = useAppSelector(state => state.admin.adminAuthed);
  const history = useHistory();
  const withoutAuthRoutes = ['/brands', '/'];

  useEffect(() => {
    if (!admin || withoutAuthRoutes.includes(history.location.pathname)) return;

    const brand = localStorage.getItem('brand');

    if (!brand) return history.push('/brands');

    (async () => {
      try {
        await dispatch(organizationStore.asyncActions.getOrganization(brand));
      } catch (e) {
        localStorage.removeItem('brand');
        history.push('/brands');
      }
    })();
  }, [admin]);

  return null;
};

export default useGetHosts;
