// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import servicesTransactions from '../../../services/transactions';

export default {
  getTransactions: createAsyncThunk(
    'transactions/getTransactions',
    async (params: { [k: string]: any } | undefined, thunkAPI) =>
      await storeAddErrorNotification(() => servicesTransactions.getTransactions(params), thunkAPI, false)
  ),
  getTransactionsByTransfer: createAsyncThunk(
    'transactions/getTransactionsByTransfer',
    async (params: { id: string }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesTransactions.getTransactionsByTransfer(params), thunkAPI, false)
  ),
  getTransfer: createAsyncThunk(
    'transactions/getTransactions',
    async (params: { [k: string]: any }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesTransactions.getTransfer(params), thunkAPI, false)
  ),
};
