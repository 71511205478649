import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
import { defaultPagination } from '../search/pagination.D';
import { cardSlice, CardStatus } from './index.D';

const initialState = {
  cards: {
    items: [],
    info: defaultPagination,
  },
  card: null,
  currencies: [],
  transactions: [],
  isLoading: false,
  error: '',
} as cardSlice;

const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    clearCards(state: typeof initialState) {
      state.cards = {
        items: [],
        info: {
          current_page: 1,
          next_page: null,
          order: null,
          order_asc_desc: 'asc',
          per_page: 10,
          previous_page: null,
          total_entries: 0,
          total_pages: 1,
        },
      };

      return state;
    },
  },
  extraReducers: {
    [asyncActions.getCards.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.cards = action.payload;
    },
    [asyncActions.getCards.pending.type]: state => {
      state.isLoading = true;
    },
    [asyncActions.getCards.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [asyncActions.getCardCurrencies.fulfilled.type]: (state, action) => {
      state.currencies = action.payload;
    },
    [asyncActions.getCardById.fulfilled.type]: (state, action) => {
      state.card = action.payload;
    },
    [asyncActions.postCard.fulfilled.type]: state => {
      if (state.card) state.card.status = CardStatus.Posted;
    },
    [asyncActions.getTransactionsBySan.fulfilled.type]: (state, action) => {
      state.transactions = action.payload;
    },
    [asyncActions.findCards.pending.type]: state => {
      state.isLoading = true;
    },
    [asyncActions.findCards.rejected.type]: state => {
      state.isLoading = false;
    },
    [asyncActions.findCards.fulfilled.type]: (state, action) => {
      state.cards = action.payload;
      state.isLoading = false;
    },
  },
});

export default {
  ...cardsSlice,
  asyncActions,
};
