// CORE
import { createSlice } from '@reduxjs/toolkit';
// SYNC ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import { defaultPagination } from '../search/pagination.D';
// TYPES
import { ITransferSettingsState } from './index.D';

const initialState: ITransferSettingsState = {
  loading: false,
  settings: {
    items: [],
    info: defaultPagination,
  },
};

const transfersSettingsSlice = createSlice({
  name: 'transfersSettings',
  initialState,
  reducers: {
    clearSettings: state => {
      state.settings = {
        items: [],
        info: defaultPagination,
      };
    },
  },
  extraReducers: {
    // CREATE
    [asyncActions.createSetting.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.createSetting.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.createSetting.fulfilled.type]: state => {
      state.loading = false;
    },
    // READ
    [asyncActions.getSettings.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.getSettings.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.getSettings.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.settings = payload;
    },
    // UPDATE
    [asyncActions.updateSetting.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.updateSetting.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.updateSetting.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.settings.items = state.settings.items.map(item => (item._id !== payload._id ? item : payload));
    },
    // DELETE
    [asyncActions.deleteSetting.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.deleteSetting.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.deleteSetting.fulfilled.type]: (state, { meta }) => {
      state.loading = false;
      state.settings.items = state.settings.items.filter(item => item._id !== meta.arg.id);
    },
  },
});

export default {
  ...transfersSettingsSlice,
  asyncActions,
};
