// TYPES
import { IForecastingDaxRawData } from '../../../../../app/store/reducers/forecastingDax/index.D';
import { regularOperations } from '../../constants';

export const getProjectedEarnings = (data: IForecastingDaxRawData[]): number => {
  const sum = data.reduce((acc, cur) => {
    if (!regularOperations.includes(cur.operation)) {
      acc += cur.result;
    }

    return acc;
  }, 0);

  return sum;
};
