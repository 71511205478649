import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { IRiskAlertsSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState = {
  riskAlerts: {
    items: [],
    info: {
      current_page: 1,
      next_page: null,
      order: null,
      order_asc_desc: 'asc',
      per_page: 10,
      previous_page: null,
      total_entries: 0,
      total_pages: 1,
    },
  },
  isLoading: false,
} as IRiskAlertsSlice;

const riskAlertsSlice = createSlice({
  name: 'riskAlerts',
  initialState,
  reducers: {
    clearRiskAlerts(state: IRiskAlertsSlice) {
      state.riskAlerts = {
        items: [],
        info: {
          current_page: 1,
          next_page: null,
          order: null,
          order_asc_desc: 'asc',
          per_page: 10,
          previous_page: null,
          total_entries: 0,
          total_pages: 1,
        },
      };

      return state;
    },
  },
  extraReducers: {
    [asyncActions.getRiskAlerts.fulfilled.type]: (state: IRiskAlertsSlice, action) => {
      state.riskAlerts = action.payload;
      state.isLoading = false;
    },
    [asyncActions.getRiskAlerts.pending.type]: (state: IRiskAlertsSlice) => {
      state.isLoading = true;
    },
    [asyncActions.getRiskAlerts.rejected.type]: (state: IRiskAlertsSlice) => {
      state.isLoading = false;
    },
  },
});

export default {
  ...riskAlertsSlice,
  asyncActions,
};
