// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICE
import digitalAssetsService from '../../../services/digitalAssets';
// TYPES
import { IEditDigitalAssets } from './index.D';

export default {
  getDigitalAssets: createAsyncThunk(
    'digitalAssets/get',
    async (params: Record<string, any>, thunkAPI) =>
      await storeAddErrorNotification(() => digitalAssetsService.getAll(), thunkAPI, false)
  ),
  setDigitalAssetAvailable: createAsyncThunk(
    'digitalAssets/setAvailable',
    async (codes: string[], thunkAPI) =>
      await storeAddErrorNotification(() => digitalAssetsService.setAvailable(codes), thunkAPI, false)
  ),
  setDigitalAssetUnavailable: createAsyncThunk(
    'digitalAssets/setUnavailable',
    async (codes: string[], thunkAPI) =>
      await storeAddErrorNotification(() => digitalAssetsService.setUnavailable(codes.join(',')), thunkAPI, false)
  ),
  editDigitalAssets: createAsyncThunk(
    'digitalAssets/edit',
    async (params: IEditDigitalAssets, thunkAPI) =>
      await storeAddErrorNotification(() => digitalAssetsService.editDigitalAssets(params), thunkAPI, false)
  ),
};
