// CORE
import { ReactElement } from 'react';
// COMPONENTS
import { Button, Box } from '@mui/material';
import BasicDialogs from '../../modules/Dialogs';
// STYLES
import useStyles from './style';

interface Props {
  onContinue: () => void;
  onLogout: () => void;
  countdownTime: number | null;
  isOpen: boolean;
}

const SessionExpiresModal = ({ onContinue, onLogout, countdownTime, isOpen }: Props): ReactElement => {
  const { btnBlock, modal } = useStyles();

  return (
    <>
      <BasicDialogs label="Your session is about to expire" onClose={onLogout} open={isOpen}>
        <Box display="flex" flexDirection="column" gap="20px" className={modal}>
          <Box>Are you still here?</Box>
          {countdownTime && <Box>For your security, we&apos;ll log out in {countdownTime} seconds</Box>}

          <Box display="flex" gap="10px" justifyContent="space-between">
            <Button className={btnBlock} size="large" color="primary" variant="outlined" onClick={onLogout}>
              Log out
            </Button>
            <Button className={btnBlock} size="large" variant="contained" color="primary" onClick={onContinue}>
              Continue session
            </Button>
          </Box>
        </Box>
      </BasicDialogs>
    </>
  );
};

export default SessionExpiresModal;
