import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import countriesService from '../../../services/countries';
// TYPES
import { IQueriesCountries } from './index.D';

export default {
  getCountries: createAsyncThunk(
    'countries/get',
    async (params: IQueriesCountries, thunkAPI) =>
      await storeAddErrorNotification(() => countriesService.get(params), thunkAPI, false)
  ),
  findAllCountries: createAsyncThunk(
    'countries/find-all',
    async (params, thunkAPI) => await storeAddErrorNotification(() => countriesService.findAll(), thunkAPI, false)
  ),
};
