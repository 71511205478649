// CORE
import { createSlice } from '@reduxjs/toolkit';
// ASYNC ACTIONS
import asyncActions from './asyncActions';
// TYPES
import { IRegistrationRequestsSlice } from './index.D';
import { defaultPagination } from '../search/pagination.D';

const initialState: IRegistrationRequestsSlice = {
  loading: false,
  requests: {
    items: [],
    info: defaultPagination,
  },
  currentRequest: null,
};

const registrationRequestsSlice = createSlice({
  name: 'registrationRequests',
  initialState,
  reducers: {
    clearRequests: state => {
      state.requests = { items: [], info: defaultPagination };
    },
    clearCurrentRequest: state => {
      state.currentRequest = null;
    },
  },
  extraReducers: {
    // GET MANY
    [asyncActions.get.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.get.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.get.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.requests = action.payload;
    },
    // GET ONE
    [asyncActions.getById.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.getById.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.getById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.currentRequest = action.payload;
    },
    // REQUEST PAYMENT
    [asyncActions.requestPayment.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.requestPayment.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.requestPayment.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.currentRequest = action.payload;
    },
    // REJECT
    [asyncActions.reject.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.reject.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.reject.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.currentRequest = action.payload;
    },
    // CONTINUE
    [asyncActions.continue.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.continue.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.continue.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.currentRequest = action.payload;
    },
  },
});

export default {
  ...registrationRequestsSlice,
  asyncActions,
};
