// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICE
import bankDetailsService from '../../../services/bankDetails';
// TYPES
import { IBankDetails, ICreateBankDetailsPayload } from './index.D';

export default {
  getBankDetails: createAsyncThunk(
    'bankDetails/get',
    async (params, thunkAPI) => await storeAddErrorNotification(() => bankDetailsService.getBankDetails(), thunkAPI)
  ),
  updateBankDetails: createAsyncThunk(
    'bankDetails/update',
    async ({ id, data }: { id: string; data: Partial<IBankDetails> }, thunkAPI) =>
      await storeAddErrorNotification(() => bankDetailsService.updateBankDetails(id, data), thunkAPI)
  ),
  createBankDetails: createAsyncThunk(
    'bankDetails/create',
    async (data: Partial<IBankDetails>, thunkAPI) =>
      await storeAddErrorNotification(() => bankDetailsService.createBankDetails(data), thunkAPI)
  ),
  createCustomBankDetails: createAsyncThunk(
    'bankDetails/createCustom',
    async (data: Partial<ICreateBankDetailsPayload>[], thunkAPI) =>
      await storeAddErrorNotification(() => bankDetailsService.createCustomBankDetails(data), thunkAPI, true)
  ),
};
