// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import servicesCards from '../../../services/cards';

export default {
  getCards: createAsyncThunk(
    'customers/getCards',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesCards.getCards(params), thunkAPI, false)
  ),
  getCardById: createAsyncThunk(
    'customers/getCardById',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesCards.getCardById(params), thunkAPI, false)
  ),
  getTransactionsBySan: createAsyncThunk(
    'customers/getTransactionsBySan',
    async (params: any, thunkAPI) =>
      await storeAddErrorNotification(() => servicesCards.getTransactionsBySan(params), thunkAPI, false)
  ),
  getCardCurrencies: createAsyncThunk(
    'get/cardCurrencies',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(() => servicesCards.getCardCurrencies(), thunkAPI, false)
  ),
  findCards: createAsyncThunk(
    'cards/find',
    async (params: { [k: string]: any } | undefined, thunkAPI) =>
      await storeAddErrorNotification(() => servicesCards.findCards(params), thunkAPI, false)
  ),
  uploadCards: createAsyncThunk(
    'cards/upload',
    async (data: FormData, thunkAPI) =>
      await storeAddErrorNotification(() => servicesCards.uploadCards(data), thunkAPI, false)
  ),
  postCard: createAsyncThunk(
    'cards/post',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesCards.postCard(params), thunkAPI, false)
  ),
};
