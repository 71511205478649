// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICE
import pricePlansService from '../../../services/pricePlans';
import { ICreatePricePlanPayload } from './index.D';

export default {
  createPricePlan: createAsyncThunk(
    'pricePlan/create',
    async (data: ICreatePricePlanPayload, thunkAPI) =>
      await storeAddErrorNotification(() => pricePlansService.createPricePlan(data), thunkAPI)
  ),
  getPricePlans: createAsyncThunk(
    'pricePlans/get',
    async (params: Record<string, string | number>, thunkAPI) =>
      await storeAddErrorNotification(() => pricePlansService.getPricePlans(params), thunkAPI)
  ),

  getPricePlansList: createAsyncThunk(
    'pricePlans/list',
    async (params, thunkAPI) => await storeAddErrorNotification(() => pricePlansService.getPricePlansList(), thunkAPI)
  ),

  updatePricePlan: createAsyncThunk(
    'pricePlan/update',
    async ({ id, data }: { id: string; data: Partial<ICreatePricePlanPayload> }, thunkAPI) =>
      await storeAddErrorNotification(() => pricePlansService.updatePricePlan({ id, data }), thunkAPI)
  ),

  // deletePricePlan: createAsyncThunk(
  //   "pricePlan/delete",
  //   async (id: string, thunkAPI) =>
  //     await storeAddErrorNotification(
  //       () => pricePlansService.deletePricePlan(id),
  //       thunkAPI
  //     )
  // ),
};
