// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand, { isJigzoBrand } from '../../helpers/getBrand';
import objectToURL from '../../helpers/objectToURL';
// TYPES
import {
  IQueriesFees,
  ICreateFeeBase,
  IFeeOpening,
  IFeeOpeningCreate,
  TCreateFeeRefund,
} from '../../store/reducers/fees/index.D';
import { IFeeValue } from '../../../components/modules/form/FeesForm/index.D';

const { get, delete: del, post, patch, put } = apiClient;
const URL = () => `organizations/${getBrand(isJigzoBrand())}`;

export default {
  // PRODUCT FEES
  getFees: (params: IQueriesFees) => get(`${URL()}/fees?${objectToURL(params)}`),
  deleteFee: (id: string) => del(`${URL()}/fees/${id}`),
  createFee: (data: IFeeValue) => post(`${URL()}/fees`, data),
  updateFee: (id: string, data: IFeeValue) => patch(`${URL()}/fees/${id}`, data),
  // BASE FEES
  getBaseFee: () => get(`${URL()}/fee-bases`),
  createBaseFee: (data: ICreateFeeBase) => post(`${URL()}/fee-bases`, data),
  updateBaseFee: (id: string, data: ICreateFeeBase) => put(`${URL()}/fee-bases/${id}`, data),
  // ACCOUNT OPENING FEES
  getOpeningFees: (params: IQueriesFees) => get(`${URL()}/fees-opening?${objectToURL(params)}`),
  createOpeningFee: (data: IFeeOpeningCreate) => post(`${URL()}/fees-opening`, data),
  deleteOpeningFee: (id: string) => del(`${URL()}/fees-opening/${id}`),
  updateOpeningFee: (id: string, data: Partial<IFeeOpening>) => patch(`${URL()}/fees-opening/${id}`, data),

  // REFUND FEES
  getRefundFees: (params: IQueriesFees) => get(`${URL()}/refund-fees?${objectToURL(params)}`),
  createRefundFee: (data: TCreateFeeRefund) => post(`${URL()}/refund-fees`, data),
  updateFeeRefund: (id: string, data: Partial<IFeeOpeningCreate>) => patch(`${URL()}/refund-fees/${id}`, data),
  deleteFeeRefund: (id: string) => del(`${URL()}/refund-fees/${id}`),
};
