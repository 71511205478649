// CORE
import { createSlice } from '@reduxjs/toolkit';
// SYNC ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import { defaultPagination } from '../search/pagination.D';
// TYPES
import { IManualTransactionsState } from './index.D';

const initialState = {
  loading: false,
  withdrawals: {
    items: [],
    info: defaultPagination,
  },
} as IManualTransactionsState;

const manualTransactionsSlice = createSlice({
  name: 'manualTransactions',
  initialState,
  reducers: {
    clearWithdrawals: state => {
      state.withdrawals = { items: [], info: defaultPagination };
    },
  },
  extraReducers: {
    // DEPOSIT
    [asyncActions.createDeposit.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.createDeposit.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.createDeposit.fulfilled.type]: state => {
      state.loading = false;
    },
    // WITHDRAWAL GET
    [asyncActions.getWithdrawals.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.getWithdrawalsMore.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.getWithdrawals.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.getWithdrawalsMore.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.getWithdrawals.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.withdrawals = action.payload;
    },
    [asyncActions.getWithdrawalsMore.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.withdrawals.info = action.payload.info;
      state.withdrawals.items = [...state.withdrawals.items, ...action.payload.items];
    },
    // WITHDRAWAL UPDATE
    [asyncActions.updateWithdrawal.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.updateWithdrawal.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.updateWithdrawal.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.withdrawals.items = state.withdrawals.items.filter(e => e._id !== payload._id);
    },
    // MANY WITHDRAWAL UPDATE
    [asyncActions.updateManyWithdrawal.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.updateManyWithdrawal.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.updateManyWithdrawal.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.withdrawals.items = state.withdrawals.items.filter(e => !payload?.includes(e._id));
    },
  },
});

export default {
  ...manualTransactionsSlice,
  asyncActions,
};
