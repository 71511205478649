// CORE
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// ASYNC ACTIONS
import asyncActions from './asyncActions';
// TYPES
import { IQuestionnaireState, IQuestionnaireSettings, IQuestionSettingsItem, IQuestionSettingsItemEdit } from './types';

const initialState = {
  loading: false,
  questionnaire: null,
} as IQuestionnaireState;

const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    edit: (state, { payload }: PayloadAction<IQuestionSettingsItemEdit>) => {
      if (state.questionnaire !== null) {
        const { index, ...updated } = payload;

        state.questionnaire.questions = [...state.questionnaire.questions].map((q, idx) => {
          if (idx !== index) return q;

          return updated;
        });
      }
    },
    add: (state, { payload }: PayloadAction<IQuestionSettingsItem>) => {
      if (state.questionnaire !== null) {
        state.questionnaire.questions = [...state.questionnaire.questions, payload];
      }
    },
    delete: (state, { payload }: PayloadAction<number>) => {
      if (state.questionnaire !== null) {
        state.questionnaire.questions = [...state.questionnaire.questions].filter((_, idx) => idx !== payload);
      }
    },
  },
  extraReducers: {
    [asyncActions.get.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.get.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.get.fulfilled.type]: (state, { payload }: PayloadAction<IQuestionnaireSettings>) => {
      state.loading = false;
      state.questionnaire = payload;
    },

    [asyncActions.update.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.update.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.update.fulfilled.type]: (state, { payload }: PayloadAction<IQuestionnaireSettings>) => {
      state.loading = false;
      state.questionnaire = payload;
    },
  },
});

export default {
  ...questionnaireSlice,
  asyncActions,
};
