// AXIOS
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { orgTypes } from '../../store/reducers/organizations/index.D';
import { ICreateJurisdictionPayload } from '../../store/reducers/jurisdictions/types';

const { get, post, delete: del } = apiClient;

const URL = () => `organizations/${getBrand()}/jurisdictions`;

export default {
  getJurisdictions: (type: orgTypes) => get(`${URL()}/${type}`),
  createJurisdictions: (data: ICreateJurisdictionPayload) => post(`${URL()}/${data.type}`, data),
  bulkCreateJurisdictions: (type: orgTypes, codes: string[]) => post(`${URL()}/${type}/bulk`, codes),
  deleteJurisdictions: (type: orgTypes, id: string) => del(`${URL()}/${type}/${id}`),
  bulkDeleteJurisdictions: (type: orgTypes, ids: string[]) => del(`${URL()}/${type}/bulk?ids=${ids.join(',')}`),
};
