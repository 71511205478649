import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeAddErrorNotification } from '../../../helpers/errors';
import apiKeyService from '../../../services/apiKeys';

export default {
  getApiKey: createAsyncThunk(
    'apiKeys/getApiKey',
    async (params, thunkAPI) => await storeAddErrorNotification(() => apiKeyService.getApiKey(), thunkAPI, false)
  ),
  createApiKey: createAsyncThunk(
    'apiKeys/createApiKey',
    async (params, thunkAPI) => await storeAddErrorNotification(() => apiKeyService.createApiKey(), thunkAPI, false)
  ),
  updateApiKey: createAsyncThunk(
    'apiKeys/updateApiKey',
    async (params, thunkAPI) => await storeAddErrorNotification(() => apiKeyService.updateApiKey(), thunkAPI, false)
  ),
};
