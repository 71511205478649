// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { INoteForm } from '../../../components/modules/form/RislAlertNoteForm/index.D';

const { get, post } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default {
  findNotesByModel: (id: string) => get(`${URL()}/notes/${id}`),
  createNote: (data: INoteForm) => post(`${URL()}/notes`, data),
};
