import { createStyles, makeStyles } from '@mui/styles';
import toRem from '../../../app/helpers/toRem';

export default makeStyles(() =>
  createStyles({
    dialogWrapper: {
      padding: '27px 38px 40px 38px',
    },
    containerWrapper: {
      '& div[role=dialog]': {
        overflowY: 'inherit',
      },
    },
    headerWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    closeWrapper: {
      cursor: 'pointer',
      '&:hover': {
        filter: 'drop-shadow(1px 1px 1px #6B6D73)',
      },
    },
    typoHeader: {
      fontSize: `${toRem(20)}!important`,
      marginBottom: `${toRem(20)}!important`,
    },
  })
);
