import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/customers/:customerId/cards/:id',
    title: 'Card Details',
    component: lazy(() => import('../../../components/views/CardDetail')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['cards_read', 'customers_report'],
    routes: [
      {
        title: 'Customers',
        path: '/customers',
        params: [],
      },
      {
        title: 'Customers Details',
        path: '/customers/:customerId',
        params: ['customerId'],
      },
      {
        title: 'Cards',
        path: '/customers/:customerId#cards',
        params: ['customerId'],
      },
    ],
  },
  {
    path: '/businesses/:businessId/cards/:id',
    title: 'Card Details',
    component: lazy(() => import('../../../components/views/CardDetail')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['cards_read', 'businesses_report'],
    routes: [
      {
        title: 'Businesses',
        path: '/businesses',
        params: [],
      },
      {
        title: 'Business Details',
        path: '/businesses/:businessId',
        params: ['businessId'],
      },
      {
        title: 'Cards',
        path: '/businesses/:businessId#cards',
        params: ['businessId'],
      },
    ],
  },
  {
    path: '/cards',
    title: 'Cards',
    component: lazy(() => import('../../../components/views/Cards')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['cards_read', 'cards_create'],
    routes: [],
  },
] as IRoutes[];
