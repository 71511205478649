// CORE
import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { IProductsCryptoSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState = {
  items: [],
  isLoading: false,
} as IProductsCryptoSlice;

const productsCryptoSlice = createSlice({
  name: 'productsCrypto',
  initialState,
  reducers: {
    clearProducts(state: IProductsCryptoSlice) {
      state.items = [];
    },
  },
  extraReducers: {
    [asyncActions.getProductsCrypto.fulfilled.type]: (state: IProductsCryptoSlice, action) => {
      state.items = action.payload;
      state.isLoading = false;
    },
  },
});

export default {
  ...productsCryptoSlice,
  asyncActions,
};
