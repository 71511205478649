// CORE
import { createSlice } from '@reduxjs/toolkit';
// SYNC ACTIONS
import asyncActions from './asyncActions';
// TYPES
import { IBankDetailsSlice } from './index.D';

const initialState = {
  loading: false,
  items: [],
} as IBankDetailsSlice;

const bankDetailsSlice = createSlice({
  name: 'bankDetails',
  initialState,
  reducers: {},
  extraReducers: {
    [asyncActions.getBankDetails.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.updateBankDetails.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.createBankDetails.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.getBankDetails.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.updateBankDetails.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.createBankDetails.rejected.type]: state => {
      state.loading = false;
    },

    [asyncActions.getBankDetails.fulfilled.type]: (state, { payload }) => {
      state.items = payload;
      state.loading = false;
    },
    [asyncActions.updateBankDetails.fulfilled.type]: (state, { payload }) => {
      const idx = state.items.findIndex(e => e._id === payload._id);
      state.items[idx] = payload;
      state.loading = false;
    },
    [asyncActions.createBankDetails.fulfilled.type]: (state, { payload }) => {
      state.items.push(payload);
      state.loading = false;
    },
  },
});

export default {
  ...bankDetailsSlice,
  asyncActions,
};
