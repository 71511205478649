// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand, { isJigzoBrand } from '../../helpers/getBrand';
import objectToURL from '../../helpers/objectToURL';
// TYPES
import { IFeeBalanceCreate, IQueriesBalanceFees } from '../../store/reducers/balanceFees/index.D';

const { get, post, patch, delete: del, put } = apiClient;
const URL = () => `organizations/${getBrand(isJigzoBrand())}/balance-fees/`;

export default {
  getBalanceFees: (params: IQueriesBalanceFees) => get(`${URL()}?${objectToURL(params)}`),
  createBalanceFee: (data: IFeeBalanceCreate) => post(URL(), data),
  updateBalanceFee: ({ id, data }: { id: string; data: Partial<IFeeBalanceCreate> }) => patch(`${URL()}${id}`, data),
  deleteBalanceFee: (id: string) => del(`${URL()}${id}`),
};
