// AXIOS
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';

const { get, post, patch } = apiClient;
const URL = () => `/organizations/${getBrand()}/client-api`;

export default {
  getApiKey: () => get(`${URL()}/`),
  createApiKey: () => post(`${URL()}/`),
  updateApiKey: () => patch(`${URL()}/`),
};
