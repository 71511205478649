// AXIOS
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
import objectToURL from '../../helpers/objectToURL';

const { get } = apiClient;

const URL = () => `organizations/${getBrand()}/clients`;

export default {
  search: (data: Record<string, string | number>) => get(`${URL()}?${objectToURL(data)}`),
  getFirstMatchUid: (data: Record<string, string | number>) => get(`${URL()}/uid?${objectToURL(data)}`),
};
