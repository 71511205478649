import { lazy } from '@loadable/component';
import { IRoutes } from './routes.D';

export default [
  {
    path: '/',
    title: '',
    component: lazy(() => import('../../components/views/Auth/Login')),
    layout: lazy(() => import('../../components/layouts/AuthLayout')),
    exact: true,
    routes: [],
    permissions: [],
  },
  {
    path: '/password/:id',
    title: '',
    component: lazy(() => import('../../components/views/Auth/Password')),
    layout: lazy(() => import('../../components/layouts/AuthLayout')),
    exact: true,
    routes: [],
    permissions: [],
  },
  {
    path: '/brands',
    title: '',
    component: lazy(() => import('../../components/views/Brands')),
    layout: lazy(() => import('../../components/layouts/AuthLayout')),
    exact: true,
    private: true,
    routes: [],
    permissions: [],
  },
  {
    path: '/password-reset',
    title: '',
    component: lazy(() => import('../../components/views/Auth/ResetPassword')),
    layout: lazy(() => import('../../components/layouts/AuthLayout')),
    exact: true,
    routes: [],
    permissions: [],
  },
] as IRoutes[];
