import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
import { IBusinessSlice, IBusiness, IQuestionnaire, IUboVerification } from './index.D';
import { defaultPagination } from '../search/pagination.D';

const initialState = {
  businesses: {
    items: [],
    info: defaultPagination,
  },
  business: null,
  contacts: {
    items: [],
    info: defaultPagination,
  },
  contact: null,
  selected: [],
  isLoading: false,
  questionnaire: null,
  uboVerifications: {
    loading: false,
    items: [],
  },
  error: '',
} as IBusinessSlice;

const customerSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setSelected: (state: IBusinessSlice, payload: PayloadAction<Array<IBusiness['_id']> | []>) => {
      state.selected = payload.payload;
    },
    clearContacts(state: IBusinessSlice) {
      state.contacts = {
        items: [],
        info: {
          current_page: 1,
          next_page: null,
          order: null,
          order_asc_desc: 'asc',
          per_page: 10,
          previous_page: null,
          total_entries: 0,
          total_pages: 1,
        },
      };

      return state;
    },
    clearBusinessContact(state: IBusinessSlice) {
      state.contact = null;
    },
  },
  extraReducers: {
    [asyncActions.getBusinesses.fulfilled.type]: (state: IBusinessSlice, action) => {
      state.businesses = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getBusinesses.pending.type]: (state: IBusinessSlice) => {
      state.isLoading = true;
      state.businesses = { items: [], info: defaultPagination };
    },
    [asyncActions.getBusinesses.rejected.type]: (state: IBusinessSlice, action: PayloadAction<IBusiness>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [asyncActions.getBusiness.fulfilled.type]: (state: IBusinessSlice, action) => {
      state.business = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getBusiness.rejected.type]: (state: IBusinessSlice, action: PayloadAction<IBusiness>) => {
      state.error = action.payload;
    },
    [asyncActions.updateBusiness.fulfilled.type]: (state: IBusinessSlice, action) => {
      state.business = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.updateBusiness.pending.type]: (state: IBusinessSlice) => {
      state.isLoading = true;
    },
    [asyncActions.updateBusiness.rejected.type]: (state: IBusinessSlice, action: PayloadAction<IBusiness>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [asyncActions.updateStatus.fulfilled.type]: (state: IBusinessSlice, action) => {
      state.business = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.updateRisk.fulfilled.type]: (state: IBusinessSlice, action) => {
      state.business = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.deleteBusinessContact.fulfilled.type]: (state: IBusinessSlice, action) => {
      state.contact = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getBusinessContacts.fulfilled.type]: (state: IBusinessSlice, action) => {
      state.contacts = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getBusinessContacts.pending.type]: (state: IBusinessSlice) => {
      state.isLoading = true;
    },
    [asyncActions.getBusinessContacts.rejected.type]: (state: IBusinessSlice, action: PayloadAction<IBusiness>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [asyncActions.getBusinessContact.fulfilled.type]: (state: IBusinessSlice, action) => {
      state.contact = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getBusinessContact.pending.type]: (state: IBusinessSlice) => {
      state.isLoading = true;
    },
    [asyncActions.getBusinessContact.rejected.type]: (state: IBusinessSlice, action: PayloadAction<IBusiness>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [asyncActions.getBusinessQuestionnaire.fulfilled.type]: (
      state: IBusinessSlice,
      action: PayloadAction<IQuestionnaire>
    ) => {
      state.questionnaire = action.payload;
    },
    [asyncActions.getUboVerifications.fulfilled.type]: (state: IBusinessSlice) => {
      state.uboVerifications.loading = true;
    },
    [asyncActions.getUboVerifications.fulfilled.type]: (state: IBusinessSlice) => {
      state.uboVerifications.loading = false;
    },
    [asyncActions.getUboVerifications.fulfilled.type]: (
      state: IBusinessSlice,
      action: PayloadAction<IUboVerification[]>
    ) => {
      state.uboVerifications.items = action.payload;
      state.uboVerifications.loading = false;
    },
  },
});

export default {
  ...customerSlice,
  asyncActions,
};
