// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';

const { post } = apiClient;
const URL = () => `organizations/${getBrand()}/announcements`;

export default {
  sendGlobalAnnouncement: (message: string) => post<{ message: string }>(`${URL()}/send-global`, { message }),
};
