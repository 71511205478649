import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { ILimitSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState = {
  limits: [],
  isLoading: false,
} as ILimitSlice;

const marginSlice = createSlice({
  name: 'limits',
  initialState,
  reducers: {
    clearLimits(state: ILimitSlice) {
      state.limits = [];

      return state;
    },
  },
  extraReducers: {
    [asyncActions.getLimits.fulfilled.type]: (state: ILimitSlice, action) => {
      state.limits = action.payload;
      state.isLoading = false;
    },
    [asyncActions.createLimit.fulfilled.type]: (state: ILimitSlice, action) => {
      state.limits = [...state.limits, action.payload];
      state.isLoading = false;
    },
    [asyncActions.updateLimit.fulfilled.type]: (state: ILimitSlice, action) => {
      state.limits = state.limits.map(limit => {
        if (limit._id === action.payload._id) return action.payload;

        return limit;
      });
      state.isLoading = false;
    },
  },
});

export default {
  ...marginSlice,
  asyncActions,
};
