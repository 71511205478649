export interface IRolesSlice {
  roles: IRole[];
  rolesOptions: string[];
  permissions: IPermission;
  role: IRole | null;
  isLoading?: boolean;
  error?: any;
}

export type IPermission = string;

export enum adminRole {
  approvedPartner,
  serviceManager,
  compliance,
  mlro,
  sysAdmin,
}

export interface IRole {
  _id: string;
  permissions: IPermission[];
  name: string;
  role: adminRole;
  organization: string;
  createdAt: string;
  updatedAt: string;
}
