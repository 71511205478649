import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import jurisdictionsService from '../../../services/jurisdictions';
// TYPES
import { ICreateJurisdictionPayload } from './types';
import { orgTypes } from '../organizations/index.D';

export default {
  get: createAsyncThunk(
    'jurisdictions/get',
    async (type: orgTypes, thunkAPI) =>
      await storeAddErrorNotification(() => jurisdictionsService.getJurisdictions(type), thunkAPI)
  ),
  create: createAsyncThunk(
    'jurisdictions/create',
    async (data: ICreateJurisdictionPayload, thunkAPI) =>
      await storeAddErrorNotification(() => jurisdictionsService.createJurisdictions(data), thunkAPI)
  ),
  bulkCreate: createAsyncThunk(
    'jurisdictions/createBulk',
    async ({ type, codes }: { type: orgTypes; codes: string[] }, thunkAPI) =>
      await storeAddErrorNotification(() => jurisdictionsService.bulkCreateJurisdictions(type, codes), thunkAPI)
  ),
  delete: createAsyncThunk(
    'jurisdictions/delete',
    async ({ type, id }: { type: orgTypes; id: string }, thunkAPI) =>
      await storeAddErrorNotification(() => jurisdictionsService.deleteJurisdictions(type, id), thunkAPI)
  ),
  bulkDelete: createAsyncThunk(
    'jurisdictions/deleteBulk',
    async ({ type, ids }: { type: orgTypes; ids: string[] }, thunkAPI) =>
      await storeAddErrorNotification(() => jurisdictionsService.bulkDeleteJurisdictions(type, ids), thunkAPI)
  ),
};
