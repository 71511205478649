import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { IMaintenanceFeeSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState = {
  maintenanceFees: [],
  isLoading: false,
} as IMaintenanceFeeSlice;

const maintenanceFeeSlice = createSlice({
  name: 'maintenanceFees',
  initialState,
  reducers: {},
  extraReducers: {
    [asyncActions.getMaintenanceFees.fulfilled.type]: (state, action) => {
      state.maintenanceFees = action.payload;
      state.isLoading = false;
    },
  },
});

export default {
  ...maintenanceFeeSlice,
  asyncActions,
};
