import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/customers',
    title: 'Customers',
    component: lazy(() => import('../../../components/views/Customers')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['customers_read', 'customers_report'],
    routes: [],
  },
  {
    path: '/customers/:customerId',
    title: 'Customer Details',
    component: lazy(() => import('../../../components/views/CustomerDetail')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['customers_read', 'customers_report'],
    routes: [
      {
        title: 'Customers',
        path: '/customers',
        params: [],
      },
    ],
  },
  {
    path: '/customers/:customerId/edit',
    title: 'Edit Customer',
    component: lazy(() => import('../../../components/modules/form/CustomerEditForm')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['customers_update'],
    routes: [
      {
        title: 'Customers',
        path: '/customers',
        params: [],
      },
      {
        title: 'Customers Details',
        path: '/customers/:customerId',
        params: ['customerId'],
      },
    ],
  },
  {
    path: '/customers/:customerId/kyc-result/:resultId',
    title: 'KYC Result',
    component: lazy(() => import('../../../components/views/KYCResult')),
    layout: lazy(() => import('../../../components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['sp_verification_read'],
    routes: [
      {
        title: 'Customers',
        path: '/customers',
        params: [],
      },
      {
        title: 'Customers Details',
        path: '/customers/:customerId',
        params: ['customerId'],
      },
    ],
  },
] as IRoutes[];
