// CORE
import { createSlice } from '@reduxjs/toolkit';
// CONSTANTS
import { defaultPagination } from '../search/pagination.D';
// ASYNC ACTIONS
import asyncActions from './asyncActions';
// TYPES
import { ILogSlice } from './index.D';

const initialState = {
  logs: {
    items: [],
    info: defaultPagination,
  },
  log: null,
  isLoading: false,
} as ILogSlice;

const logSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    clearLogs(state: ILogSlice) {
      state.logs = {
        items: [],
        info: defaultPagination,
      };

      return state;
    },
    clearLog(state: ILogSlice) {
      state.log = null;

      return state;
    },
  },
  extraReducers: {
    [asyncActions.getLogs.fulfilled.type]: (state: ILogSlice, action) => {
      state.logs = action.payload;
      state.isLoading = false;
    },
    [asyncActions.getLogById.fulfilled.type]: (state: ILogSlice, action) => {
      state.log = action.payload;
      state.isLoading = false;
    },
  },
});

export default {
  ...logSlice,
  asyncActions,
};
