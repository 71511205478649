// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand, { isJigzoBrand } from '../../helpers/getBrand';

const { get } = apiClient;

const URL = () => `organizations/${getBrand(isJigzoBrand())}/base-price-tiers`;

export default {
  getBasePriceTiers: () => get(URL()),
};
